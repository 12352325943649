import { Link, useLocation } from "react-router-dom";
import "./drejtoria.css";
import { useEffect, useState } from "react";
import { drejtoriaData } from "./drejtoriaData.js";
import axios from "axios";

const Drejtoria = () => {
  const [historikuImages, setHistorikuImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const pagePath = useLocation();

  const fetchHistorikuImages = async () => {
    try {
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/historiku/images"
      );
      setHistorikuImages(response.data.images);
    } catch (error) {
      console.error("Error fetching historiku images:", error);
    }
  };

  useEffect(() => {
    if (pagePath.pathname === "/drejtoria/historiku") {
      fetchHistorikuImages();
    }
  }, [pagePath.pathname]);

  const pageData = drejtoriaData.find(
    (item) => item.path === pagePath.pathname
  );

  if (!pageData) {
    return <div>Page not found</div>;
  }

  const isStrukturaPath = pagePath.pathname === "/drejtoria/struktura";
  const content = pageData.content || [];
  const leftItems = isStrukturaPath ? content.slice(0, 5) : [];
  const rightItems = isStrukturaPath ? content.slice(5, 9) : [];

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % historikuImages.length
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? historikuImages.length - 1 : prevIndex - 1
    );
  };

  const renderPageContent = () => {
    if (pagePath.pathname === "/drejtoria/historiku") {
      return (
        <div className="page-content-historiku">
          <div className="left-content-historiku">{pageData.leftContent}</div>
          <div className="right-content-historiku">
            <button onClick={() => openModal(0)}>Galeri</button>
          </div>
        </div>
      );
    } else if (pagePath.pathname === "/drejtoria/kla-kpe-kdzh-kqe") {
      return (
        <div className="page-content-kla">
          <div className="left-content-kla">{pageData.leftContent}</div>
          <div className="right-content-kla">
            {Array.isArray(pageData.rightContent) &&
              pageData.rightContent.map((content, index) => (
                <div className="council-member" key={index}>
                  <div className="member-photo">
                    <img src={content.image} alt={content.name} />
                  </div>
                  <div className="member-info">
                    <p className="name">{content.name}</p>
                    <p className="info member">{content.memberPosition}</p>
                    <p className="other-info">{content.institution}</p>
                    <p className="other-info">{content.position}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    } else if (
      pagePath.pathname === "/drejtoria/organizimi-i-rrjetit-arkivor"
    ) {
      return (
        <div className="page-content-ra">
          <div className="left-content-ra">{pageData.leftContent}</div>
          <div className="right-content-ra">
            {Array.isArray(pageData.rightContent) &&
              pageData.rightContent.map((section, index) => (
                <div key={index} className="rrjeti-arkivor">
                  <h2 className="right-title">{section.title}</h2>
                  <p className="right-subtitle">{section.subtitle}</p>
                  <ul>
                    {Array.isArray(section.list) &&
                      section.list.map((item, itemIndex) =>
                        typeof item === "string" ? (
                          <p key={itemIndex} className="private">
                            {item}
                          </p>
                        ) : (
                          <li key={itemIndex}>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.name}
                            </a>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      );
    } else if (isStrukturaPath) {
      return (
        <div className="page-content-struktura">
          <div className="left-content-struktura">
            {leftItems.map((item, index) => (
              <div key={index} className="struktura-item">
                <Link
                  to={item.path || "#"}
                  className={`list-title ${
                    item.path === "" ? "none" : "underline"
                  }`}
                >
                  {item.name}
                </Link>
                <ul>
                  {item.sektoret.map((sector, subIndex) => (
                    <li key={subIndex}>
                      <Link to={sector.path}>{sector.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="right-content-struktura">
            {rightItems.map((item, index) => (
              <div key={index} className="struktura-item">
                <Link
                  to={item.path || "#"}
                  className={`list-title ${
                    item.path === "" ? "none" : "underline"
                  }`}
                >
                  {item.name}
                </Link>
                <ul>
                  {item.sektoret.map((sector, subIndex) => (
                    <li key={subIndex}>
                      <Link to={sector.path}>{sector.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return <div>No content available</div>;
  };

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">{pageData.title}</h1>
      </div>
      {renderPageContent()}
      {isModalOpen && (
        <div className="modal-post-historiku">
          <button className="modal-close-historiku" onClick={closeModal}>
            &times;
          </button>
          <button className="modal-prev-post-historiku" onClick={prevSlide}>
            &#10094;
          </button>
          <div className="modal-content-post-historiku">
            <img
              src={historikuImages[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
            />
          </div>
          <button className="modal-next-post-historiku" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      )}
    </div>
  );
};

export default Drejtoria;
