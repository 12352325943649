import ArditBido from "../Assets/Images/KeshilliArkivave/ArditBido.jpg";
import ArbenDervishllari from "../Assets/Images/KeshilliArkivave/Arben Dervishllari.png";
import ArmandoBoce from "../Assets/Images/KeshilliArkivave/Armando Boce.jpg";
import ElsaSaka from "../Assets/Images/KeshilliArkivave/Elsa Saka.jpeg";
import LorencAgalliu from "../Assets/Images/KeshilliArkivave/LorencAgalliu.jpeg";
import ZefMala from "../Assets/Images/DrejtoretNeVite/ZefMala.jpg";
import GaqoPeristeri from "../Assets/Images/DrejtoretNeVite/GaqoPeristeri.jpg";
import VeliDedi from "../Assets/Images/DrejtoretNeVite/VeliDedi.jpg";
import SkendërHajro from "../Assets/Images/DrejtoretNeVite/SkenderHajro.jpg";
import ThomaMurzaku from "../Assets/Images/DrejtoretNeVite/ThomaMurzaku.jpg";
import LuanMalltezi from "../Assets/Images/DrejtoretNeVite/LuanMalltezi.jpg";
import ShabanSinani from "../Assets/Images/DrejtoretNeVite/ShabanSinani.jpg";
import GjetNdoj from "../Assets/Images/DrejtoretNeVite/GjetNdoj.jpg";
import IsmailNuhiu from "../Assets/Images/DrejtoretNeVite/IsmailNuhiu.jpg";
import SefedinMeçe from "../Assets/Images/DrejtoretNeVite/SefedinMeçe.jpg";
import DhimitërKotini from "../Assets/Images/DrejtoretNeVite/DhimiterKotini.jpg";
import SpiroRusho from "../Assets/Images/DrejtoretNeVite/SpiroRusho.jpg";
import GazmendShpuza from "../Assets/Images/DrejtoretNeVite/GazmendShpuza.jpg";
import ZamirShtylla from "../Assets/Images/DrejtoretNeVite/ZamirShtylla.jpg";
import NevilaNika from "../Assets/Images/DrejtoretNeVite/NevilaNika.jpg";
export const sektoretData = [
  {
    path: "",
    title: "Drejtori i Përgjithshëm i Arkivave",
    image: null,
    name: "Ardit Bido",
    content: (
      <>
        <p>
          Ardit Bido ka mbrojtur gradën «Doktor i Shkencave» në Marrëdhënie
          Ndërkombëtare në Universitetin e Tiranës, lëmë në të cilën ka kryer
          edhe studimet Master. Ciklin e parë të shkollës së lartë e ka
          përfunduar për Gazetari në të njëjtin universitet. Punimet akademike
          përfshijnë studime të botuara në revista shkencore brenda e jashtë
          vendit, kumtesa të referuara në konferenca kombëtare e ndërkombëtare,
          si edhe dy monografi: “Kisha Ortodokse Shqiptare: një histori politike
          (1878-1937)” dhe “Koncesioni editorial i medias shqiptare”.
        </p>
        <p>
          Ardit Bido ka një përvojë disavjeçare në shtypin e shkruar dhe mediat
          audiovizive, përfshi drejtimin e redaksive të lajmeve të «TV Ora
          News», gazetës «Standard», gazetës «Kombëtare», etj. Po ashtu, ai ka
          menaxhuar departamentet e komunikimit të disa institucioneve
          akademike, shtetërore dhe private. Prej vitit 2012, është pedagog
          pranë Departamentit të Gazetarisë të Universitetit të Tiranës dhe
          pranë Universitetit Metropolitan Tirana. Ai ka pasur angazhime publike
          dhe komunitare, përfshi drejtimin e disa organizatave rinore,
          pjesëmarrjen në borde drejtuese universitare e kërkimore dhe zgjedhjen
          si nënkryetar i Këshillit Bashkiak të Njësisë nr. 9 në Tiranë.
        </p>
        <p>
          Ardit Bido ka ndjekur një seri kursesh e trajnimesh profesionale
          lidhur me komunikimin dhe marrëdhëniet ndërkombëtare të organizuara
          nga NATO, Ambasada Amerikane në Tiranë, etj., në Shqipëri, Belgjikë,
          Luksemburg, Serbi, e disa vende të tjera. Përveç gjuhës amtare shqipe,
          zotëron gjuhët angleze e greke dhe ka njohuri të gjuhëve italiane e
          franceze. Ardit Bido është lindur në Sarandë më 20 korrik 1987 dhe
          është babai i një vajze, Deas.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/funksionet-e-drejtorit-te-pergjithshem",
    title: "Funksionet e Drejtorit të Përgjithshëm",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtori i Përgjithshëm i Arkivave është titullari i institucionit.
          Drejtori i Përgjithshëm i Arkivave ka këto detyra: Është kryesuesi i
          rrjetit arkivor kombëtar dhe ushtron drejtimin metodik-profesional të
          punës në arkivat shtetërore të sistemit, arkivat e institucioneve të
          administratës shtetërore në veprim dhe në arkivat e tjera, të parapara
          në ligjin “Për arkivat”;
        </p>
        <p>
          Nënshkruan marrëveshjet e bashkëpunimit ndërinstitucional; Përfaqëson
          arkivat shtetërore të vendit në organizmat dhe institucionet
          ndërkombëtare, ku ato janë anëtarësuar, dhe ushtron të drejtën e votës
          në emër të tyre;
        </p>
        <p>
          Nënshkruan, në emër të institucionit që drejton, marrëveshje dy ose më
          shumë palëshe përbashkëpunim ndërarkivistik me partnerë të huaj;
          Përgjigjet për veprimtarinë e Drejtorisë së Përgjithshme të Arkivave;
          U rekomandon organeve përgjegjëse, institucioneve të pushtetit e të
          administratës qendrore e vendore dhe subjekteve joshtetërore
          shfuqizimin e akteve administrative joligjore për punën me dokumente,
          të miratuara prej tyre, në përputhje me legjislacionin në fuqi;
          Bashkërendon punën e strukturave të Drejtorisë së Përgjithshme të
          Arkivave, i ndihmon ato për funksionimin normal dhe zgjidh çështjet që
          tejkalojnë përgjegjësitë e tyre si dhe miraton rregullore, urdhra e
          udhëzime për funksionimin e brendshëm të strukturave; Miraton planet
          vjetore të drejtorive që funksionojnë pranë Drejtorisë së
          Përgjithshme; U delegon autoritet përfaqësimi drejtorëve të drejtorive
          për probleme të ndryshme të punës, sipas Kodit të Procedurave
          Administrative. Lëshon autorizimin e inspektimit, në përputhje dhe në
          bazë të programit të miratuar të inspektimit; Vendos për shtyrjen e
          kohëzgjatjes së autorizimit, zëvendësimin e inspektorëve, gjatë
          inspektimit, shqyrton ankimin e veçantë ndaj vendimeve për masat
          urgjente të marra nga inspektorët, sipas ligjit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoret-ne-vite",
    title: "Drejtorët në vite",
    image: null,
    name: "",
    content: (
      <div className="directors">
        <div className="director">
          <img src={ZefMala} alt="Zef Mala" />
          <div className="director-info">
            <p className="name">Zef Mala</p>
            <p className="period">Qershor 1949 - Shtator 1952</p>
          </div>
        </div>
        <div className="director">
          <img src={IsmailNuhiu} alt="Ismail Nuhiu" />
          <div className="director-info">
            <p className="name">Ismail Nuhiu</p>
            <p className="period">Tetor 1952 - Tetor 1953</p>
          </div>
        </div>
        <div className="director">
          <img src={GaqoPeristeri} alt="Gaqo Peristeri" />
          <div className="director-info">
            <p className="name">Gaqo Peristeri</p>
            <p className="period">Tetor 1953 - Dhjetor 1953</p>
          </div>
        </div>
        <div className="director">
          <img src={SefedinMeçe} alt="Sefedin Meçe" />
          <div className="director-info">
            <p className="name">Sefedin Meçe</p>
            <p className="period">Janar 1954 - Shtator 1955</p>
          </div>
        </div>
        <div className="director">
          <img src={VeliDedi} alt="Veli Dedi" />
          <div className="director-info">
            <p className="name">Veli Dedi</p>
            <p className="period">Shtator 1956 - Qershor 1962</p>
          </div>
        </div>
        <div className="director">
          <img src={DhimitërKotini} alt="Dhimitër Kotini" />
          <div className="director-info">
            <p className="name">Dhimitër Kotini</p>
            <p className="period">Qershor 1962 - Nëntor 1966</p>
          </div>
        </div>
        <div className="director">
          <img src={SkendërHajro} alt="Skendër Hajro" />
          <div className="director-info">
            <p className="name">Skendër Hajro</p>
            <p className="period">Dhjetor 1966 - Gusht 1973</p>
          </div>
        </div>
        <div className="director">
          <img src={SpiroRusho} alt="Spiro Rusho" />
          <div className="director-info">
            <p className="name">Spiro Rusho</p>
            <p className="period">Shtator 1973 - Maj 1979</p>
          </div>
        </div>
        <div className="director">
          <img src={ThomaMurzaku} alt="Thoma Murzaku" />
          <div className="director-info">
            <p className="name">Thoma Murzaku</p>
            <p className="period">Maj 1979 - Mars 1989</p>
          </div>
        </div>
        <div className="director">
          <img src={GazmendShpuza} alt="Gazmend Shpuza" />
          <div className="director-info">
            <p className="name">Gazmend Shpuza</p>
            <p className="period">Mars 1989 - Gusht 1992</p>
          </div>
        </div>
        <div className="director">
          <img src={LuanMalltezi} alt="Luan Malltezi" />
          <div className="director-info">
            <p className="name">Luan Malltezi</p>
            <p className="period">Gusht 1992 - Gusht 1997</p>
          </div>
        </div>
        <div className="director">
          <img src={ZamirShtylla} alt="Zamir Shtylla" />
          <div className="director-info">
            <p className="name">Zamir Shtylla</p>
            <p className="period">Shtator 1997 - Nëntor 1999</p>
          </div>
        </div>
        <div className="director">
          <img src={ShabanSinani} alt="Shaban Sinani" />
          <div className="director-info">
            <p className="name">Shaban Sinani</p>
            <p className="period">Nëntor 1999 - Tetor 2005</p>
          </div>
        </div>
        <div className="director">
          <img src={NevilaNika} alt="Nevila Nika" />
          <div className="director-info">
            <p className="name">Nevila Nika</p>
            <p className="period">Tetor 2005 - Tetor 2013</p>
          </div>
        </div>
        <div className="director">
          <img src={GjetNdoj} alt="Gjet Ndoj" />
          <div className="director-info">
            <p className="name">Gjet Ndoj</p>
            <p className="period">Tetor 2013 - Shtator 2016</p>
          </div>
        </div>
        <div className="director">
          <img src={ArditBido} alt="Ardit Bido" />
          <div className="director-info">
            <p className="name">Ardit Bido</p>
            <p className="period">Shtator 2016 - Mars 2025</p>
          </div>
        </div>
      </div>
    ),
  },
  {
    path: "/struktura/zevendesdrejtori-i-pergjithshem",
    title: "Zëvendësdrejtori i Përgjithshëm",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Zëvendësdrejtori i Përgjithshëm asiston dhe mbështetet drejtorin e
          Përgjithshëm të Arkivave, për përmbushjen e objektivave të
          institucionit, duke bashkërenduar dhe mbikqyrur veprimtari dhe
          funksione të veçanta të strukturave të DPA-së, përcaktuar me urdhër
          dhe delegim të drejtorit të Përgjithshëm, si dhe në rregulloren e
          brendshme.
        </p>
        <p>
          Në ushtrimin e funksioneve të tij, Zëvendësdrejtori i Përgjithshëm
          mbikëqyr punën e strukturave të brendshme, sipas fushave të
          përcaktuara, duke siguruar funksionimin e tyre të rregullt dhe efikas.
          Ai ka një rol aktiv në bashkërendimin e punës ndërmjet drejtorive dhe
          sektorëve të ndryshëm, duke ndihmuar në zgjidhjen e çështjeve që
          kërkojnë ndërveprim ndërstruktural.
        </p>
        <p>
          Zëvendësdrejtori i Përgjithshëm përfaqësohet si anëtar në organizmat
          që funksionojnë pranë institucionit dhe kryeson mbledhjet e Komisionit
          Qendror të Ekspertizës, duke drejtuar proceset e vlerësimit dhe
          seleksionimit të dokumentacionit arkivor. Ai monitoron në vazhdimësi
          veprimtarinë e strukturave për shfrytëzimin, komunikimin, ruajtjen dhe
          pasurimin e arkivave me dokumente, dhe propozon masa për përmirësimin
          e punës së tyre.
        </p>
        <p>
          Gjithashtu, ndjek ecurinë e detyrave të deleguara nga Drejtori i
          Përgjithshëm dhe jep rekomandime për rritjen e cilësisë dhe
          efektivitetit të proceseve arkivore. Ai zbaton çdo detyrë tjetër të
          ngarkuar nga Drejtori i Përgjithshëm, në funksion të mbarëvajtjes së
          punës institucionale dhe përmbushjes së misionit të Drejtorisë së
          Përgjithshme të Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/sektori-menaxhimit-dhe-trajnimeve",
    title: "Sektori i Menaxhimit dhe Trajnimeve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Menaxhimit dhe Trajnimeve është një strukturë në varësi të
          drejtpërdrejtë të Drejtorit të Përgjithshëm të Arkivave. Ky sektor
          ushtron funksionin e sekretariatit teknik, duke u angazhuar në
          menaxhimin dhe organizimin e axhendës zyrtare të titullarit dhe
          zëvendësit të tij, si dhe në koordinimin dhe mbështetjen
          administrative të veprimtarisë së organeve kolegjiale që funksionojnë
          pranë Drejtorisë së Përgjithshme të Arkivave. Po ashtu, ai ka një rol
          qendror në organizimin, drejtimin dhe monitorimin e seminareve dhe
          trajnimeve që zhvillohen për përgatitjen profesionale dhe
          metodologjike të punonjësve të rrjetit arkivor kombëtar.
        </p>
        <p>
          Struktura e këtij sektori përbëhet nga përgjegjësi i sektorit,
          specialistët dhe sekretari, të cilët funksionojnë në bashkëpunim për
          përmbushjen e detyrave të ngarkuara.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues dhe koordinues për të gjitha
          veprimtaritë e sektorit. Ai menaxhon funksionimin e organeve
          kolegjiale që veprojnë pranë institucionit, përgatit njoftimet për
          mbledhjet dhe ndihmon në hartimin e akteve përkatëse. Ai organizon dhe
          drejton trajnime për përmirësimin profesional të punonjësve të
          arkivave, si dhe udhëheq punën për gjurmimin dhe rigjetjen e
          dokumenteve me vlerë historike të humbura ose të vjedhura, me qëllim
          përfshirjen e tyre në fondin arkivor kombëtar. Përgjegjësi mbikëqyr
          dhe udhëzon në mënyrë të vazhdueshme punën e specialistëve dhe
          sekretarit të sektorit, si dhe përmbush çdo detyrë tjetër të ngarkuar
          nga eprorët e tij.
        </p>
        <p>
          Specialistët e këtij sektori mbështesin funksionet operative dhe
          koordinuese të sektorit. Ata përgatisin njoftime për zhvillimin e
          mbledhjeve të organeve dhe komisioneve që funksionojnë pranë DPA-së,
          ndihmojnë në hartimin e dokumentacionit përkatës dhe kontribuojnë në
          procesin e organizimit të trajnimeve. Gjithashtu, specialistët ndjekin
          nga afër mbarëvajtjen e trajnimeve, evidentojnë pjesëmarrjen dhe
          mbajnë dokumentacionin përkatës. Ata angazhohen në kërkime për
          dokumente arkivore me rëndësi historike, si dhe përmbushin çdo detyrë
          të ngarkuar nga eprorët apo Drejtori i Përgjithshëm.
        </p>
        <p>
          Sekretari i sektorit është përgjegjës për menaxhimin e axhendës
          zyrtare të Drejtorit të Përgjithshëm dhe zëvendësdrejtorëve, duke
          organizuar takimet dhe koordinuar komunikimet zyrtare. Ai bashkërendon
          punën me zyrën e sekretari-arkivit për qarkullimin dhe siglimin e
          korrespondencës zyrtare që del nga drejtuesit e institucionit.
          Sekretari kryen me përpikmëri të gjitha detyrat e ngarkuara nga
          eprorët, duke garantuar efikasitet në komunikimin dhe administrimin e
          agjendës së drejtuesve.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/sektori-auditimit-te-brendshem",
    title: "Sektori i Auditimit të Brendshëm",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Auditit të Brendshëm është një strukturë funksionale në
          varësi të drejtpërdrejtë të Drejtorit të Përgjithshëm të Arkivave.
          Misioni i tij është të garantojë integritetin, efikasitetin dhe
          përputhshmërinë ligjore të veprimtarisë së brendshme të institucionit,
          përmes proceseve të auditimit të strukturuara dhe të pavarura. Ky
          sektor ka një rol të rëndësishëm në identifikimin dhe parandalimin e
          problematikave të mundshme, si dhe në rekomandimin e masave për
          përmirësimin e funksionimit të brendshëm të Drejtorisë së Përgjithshme
          të Arkivave.
        </p>
        <p>
          Në përbërje të tij, sektori ka një përgjegjës dhe dy specialistë, të
          cilët punojnë në bashkëpunim për realizimin e objektivave të
          auditimit, në përputhje me legjislacionin kombëtar dhe standardet
          ndërkombëtare të auditimit të brendshëm. Veprimtaria e sektorit
          përfshin auditimin e rregullsisë dhe efektivitetit të punës në
          drejtoritë dhe sektorët përkatës, si dhe vlerësimin teknik të
          raporteve të auditimeve të jashtme që i bëhen institucionit.
        </p>
        <p>
          Sektori i Auditit të Brendshëm funksionon gjithashtu si Struktura
          Përgjegjëse për Sinjalizuesit, duke garantuar një mekanizëm të
          besueshëm dhe të sigurt për denoncimin e çdo veprimi të dyshuar si të
          paligjshëm apo të papërshtatshëm brenda institucionit. Në këtë kuadër,
          sektori angazhohet në mbrojtjen e sinjalizuesve dhe trajtimin e
          rasteve në përputhje me parimet e transparencës dhe përgjegjshmërisë
          institucionale.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit",
    title: "Drejtoria e Inspektimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Inspektimit është një strukturë inspektuese brenda
          Drejtorisë së Përgjithshme të Arkivave, e krijuar për të garantuar
          respektimin e standardeve metodologjike dhe profesionale në fushën e
          veprimtarisë arkivore në nivel kombëtar. Ajo funksionon në përputhje
          me legjislacionin në fuqi për inspektimet dhe është përgjegjëse për
          vlerësimin dhe përmirësimin e punës arkivore në të gjitha nivelet
          institucionale, shtetërore dhe joshtetërore, përfshirë edhe vetë
          DPA-në.
        </p>
        <p>
          Drejtoria e Inspektimit dhe Trajnimeve është e organizuar në 4 (katër)
          sektorë:
          <ol>
            <li>Sektori i Inspektimit Qendror,</li>
            <li>Sektori i Inspektimit Veri,</li>
            <li>Sektori i Inspektimit Jug,</li>
            <li>Sektori Rregullator</li>
          </ol>
        </p>
        <p>
          Drejtoria zhvillon inspektime për të monitoruar zbatimin e praktikave
          të përpunimit, ruajtjes dhe administrimit të dokumentacionit arkivor,
          duke kontrolluar veçanërisht cilësinë e përpunimit të dokumenteve
          përpara dorëzimit në arkivat e përhershme. Gjithashtu, ajo kontrollon
          kushtet e sigurisë dhe ruajtjes së dokumenteve në institucionet
          publike dhe private, si pjesë e garantimit të qëndrueshmërisë së
          trashëgimisë dokumentare.
        </p>
        <p>
          Në funksion të rolit të saj, Drejtoria e Inspektimit propozon akte
          ligjore dhe nënligjore për përmirësimin e veprimtarisë arkivore,
          bashkëpunon ngushtë me strukturat e tjera të DPA-së dhe mirëmban
          databazën zyrtare të institucioneve fondkrijuese. Përmes inspektorëve
          të saj, ajo kryen gjithashtu funksionin e personit të kontaktit dhe të
          mbikëqyrësit të zbatimit të politikave arkivore në institucionet e
          caktuara, në përputhje me urdhrat e Drejtorit të Përgjithshëm të
          Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit/drejtori-i-inspektimit",
    title: "Drejtori i Inspektimit",
    image: null,
    name: "",
    content: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit/sektori-i-inspektimit-qendror",
    title: "Sektori i Inspektimit Qëndror",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Inspektimit Qendror është strukturë e specializuar brenda
          Drejtorisë së Inspektimit, që mbulon veprimtarinë inspektuese në
          institucionet shtetërore të nivelit qendror. Ky sektor ka një rol kyç
          në garantimin e ligjshmërisë dhe respektimit të standardeve
          profesionale në administrimin dhe përpunimin e dokumenteve arkivore
          nga institucionet publike dhe privatë me rëndësi historike kombëtare.
        </p>
        <p>
          Në përbërje të sektorit janë përgjegjësi i sektorit dhe inspektorët.
          Përgjegjësi i sektorit organizon, drejton dhe mbikëqyr veprimtarinë e
          inspektimeve në institucione, koordinon marrëdhëniet me to dhe udhëheq
          punën e inspektorëve në terren. Ai mban kontakte zyrtare me
          institucionet fondkrijuese, harton raportet inspektuese dhe kujdeset
          për mirëmbajtjen e databazës së konsoliduar të institucioneve
          qendrore.
        </p>
        <p>
          Inspektorët e sektorit ushtrojnë kontrolle për respektimin e
          legjislacionit në fuqi për arkivat dhe inspektimin, ndërhyjnë në
          rastet kur evidentohen shkelje proceduriale apo mangësi metodologjike
          dhe marrin masat përkatëse. Ata bashkëpunojnë ngushtësisht me Sektorin
          e Menaxhimit dhe Trajnimeve për rritjen e kapaciteteve profesionale të
          punonjësve të arkivave dhe sekretares së institucioneve qendrore.
          Njëkohësisht, ata ndihmojnë në përgatitjen e dokumentacionit për
          dorëzim në arkivat e përhershme, si dhe japin rekomandime për
          përmirësimin e akteve rregullatore në fushën arkivore.
        </p>
        <p>
          Përmes punës së tij, Sektori i Inspektimit Qendror siguron që
          trashëgimia dokumentare të trajtohet dhe të ruhet në përputhje me
          standardet më të larta, duke kontribuar në forcimin dhe modernizimin e
          sistemit arkivor kombëtar.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit/sektori-i-inspektimit-veri",
    title: "Sektori i Inspektimit Veri",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Inspektimit Veri është strukturë funksionale e Drejtorisë së
          Inspektimit në Drejtorinë e Përgjithshme të Arkivave, që mbulon
          aktivitetin inspektues në institucionet shtetërore rajonale dhe
          vendore në zonën veriore të vendit. Ky sektor përbëhet nga përgjegjësi
          i sektorit dhe inspektorë të specializuar.
        </p>
        <p>
          Qëllimi kryesor i këtij sektori është të sigurojë përputhshmërinë e
          punës arkivore me kërkesat ligjore dhe standardet profesionale, të
          monitorojë përpunimin e dokumenteve për dorëzim në arkivat e
          përhershme dhe të garantojë mbrojtjen e pasurisë dokumentare
          kombëtare.
        </p>
        <p>
          Përgjegjësi i sektorit organizon, drejton dhe mbikëqyr veprimtarinë
          inspektuese, mban kontakte të rregullta me institucionet fondkrijuese
          vendore dhe kujdeset për përditësimin e databazës së tyre. Ai harton
          raportet përkatëse për inspektimet dhe ofron mbështetje profesionale
          për inspektorët në zgjidhjen e problematikave të hasura.
        </p>
        <p>
          Inspektorët zbatojnë kontrolle të drejtpërdrejta në institucionet
          vendore për të verifikuar zbatimin e ligjit “Për Arkivat” dhe ligjit
          “Për Inspektimin në Republikën e Shqipërisë”, si dhe për të marrë masa
          administrative kur evidentohen shkelje. Ata bashkëpunojnë me sektorë
          të tjerë të DPA-së për trajnimin dhe kualifikimin e arkivistëve dhe
          sekretarive, ndihmojnë komisionet e ekspertizës dhe kujdesen për
          dorëzimin e dokumentacionit të përpunuar në arkivat përkatëse.
        </p>
        <p>
          Gjithashtu, inspektorët përfaqësojnë DPA-në në seanca gjyqësore brenda
          qarkut të tyre, kur është e nevojshme, dhe japin kontribut në hartimin
          dhe përmirësimin e akteve rregullatore që rregullojnë fushën arkivore.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit/sektori-i-inspektimit-jug",
    title: "Sektori i Inspektimit Jug",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Inspektimit Jug është një strukturë e specializuar në kuadër
          të Drejtorisë së Inspektimit, pranë Drejtorisë së Përgjithshme të
          Arkivave. Ky sektor ka për detyrë inspektimin dhe mbikëqyrjen e
          veprimtarisë arkivore në institucionet shtetërore rajonale dhe vendore
          në zonën jugore të vendit, në përputhje me ligjin “Për Arkivat” dhe
          ligjin “Për Inspektimin në Republikën e Shqipërisë”.
        </p>
        <p>
          Përgjegjësi i sektorit organizon, drejton dhe koordinon të gjithë
          veprimtarinë e sektorit dhe të inspektorëve që janë në varësi të tij.
          Ai siguron funksionimin e rregullt të punës së inspektimit duke
          hartuar raporte inspektimi, mirëmbajtur databazën e konsoliduar të
          institucioneve që mbikëqyren, dhe duke zhvilluar takime e konsultime
          me arkivistët e institucioneve vendore për mbarëvajtjen e veprimtarisë
          arkivore. Gjithashtu, përgjegjësi mban kontakte zyrtare me
          institucionet publike, me qëllim dorëzimin e dokumenteve me vlerë të
          përhershme në arkivat shtetërore, sipas afateve të përcaktuara nga
          ligji.
        </p>
        <p>
          Inspektorët e sektorit ushtrojnë inspektime për ligjshmërinë dhe
          respektimin e standardeve metodologjike-profesionale në institucionet
          që krijojnë dhe administrojnë dokumente arkivore. Ata kanë të drejtë
          të pezullojnë procese të punës arkivore kur konstatohen shkelje dhe të
          ndërmarrin masa administrative sipas legjislacionit në fuqi. Po ashtu,
          inspektorët ndihmojnë institucionet në përpunimin dhe dorëzimin e
          dokumentacionit arkivor në arkivat e përhershme, mirëmbajnë databazën
          e fondkrijuesve që kanë në mbikëqyrje dhe japin mendime për
          përmirësimin e akteve ligjore dhe nënligjore që rregullojnë fushën
          arkivore. Në kuadër të bashkëpunimit ndërinstitucional, ata
          bashkëpunojnë ngushtësisht me Sektorin e Menaxhimit dhe Trajnimeve për
          rritjen e kapaciteteve profesionale të arkivistëve dhe sekretarëve të
          institucioneve të zonës.
        </p>
        <p>
          Gjithashtu, inspektorët përfaqësojnë Drejtorinë e Përgjithshme të
          Arkivave në seanca gjyqësore brenda qarqeve përkatëse, si dhe
          ndihmojnë në funksionimin e komisioneve të ekspertizës në
          institucionet vendore.
        </p>
        <p>
          Përveç këtyre përgjegjësive të përbashkëta, inspektorët rajonalë të
          qarqeve Shkodër, Dibër dhe Gjirokastër kanë detyrime shtesë që lidhen
          me administrimin financiar dhe sigurinë materiale të arkivave. Ata
          dorëzojnë në kohë faturat për pagesa pranë Drejtorisë së Financës dhe
          Shërbimeve Mbështetëse, sipas urdhrave përkatës, si dhe garantojnë
          sigurinë e depove dhe materialeve arkivore me afat ruajtjeje të
          përkohshme që ndodhen në ruajtje fizike.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit/sektori-i-rregullator",
    title: "Sektori Rregullator",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori Rregullator është një strukturë e specializuar që ka në fokus
          mbikëqyrjen, drejtimin dhe koordinimin e veprimtarive të përpunimit
          tekniko-shkencor të dokumentacionit arkivor në arkivat e përhershëm,
          në përputhje me marrëveshjet dypalëshe të lidhura ndërmjet
          institucioneve shtetërore dhe Drejtorisë së Përgjithshme të Arkivave.
        </p>
        <p>
          Përgjegjësi i sektorit ka si detyrë kryesore organizimin, drejtimin
          dhe kontrollin e punës së sektorit dhe të gjithë punonjësve nën
          varësinë e tij. Ai mbikëqyr dhe kontrollon punën e projekteve për
          përpunimin e dokumentacionit arkivor që ndodhet në arkivat e
          përhershëm, të realizuar nga punonjës të institucioneve në veprim.
          Gjithashtu, ndjek në mënyrë të vazhdueshme ecurinë e punës së
          inspektorëve për përmbushjen e objektivave të sektorit. Ai harton
          planet e punës për stafin e angazhuar në projekte, kontrollon zbatimin
          e tyre dhe udhëzon në lidhje me problematikat që lindin gjatë
          përpunimit tekniko-shkencor të dokumentacionit, deri në dorëzimin
          përfundimtar në vendruajtje me inventarë. Një tjetër funksion i
          rëndësishëm i tij është verifikimi i inventarëve dhe shqyrtimi i
          vlefshmërisë së dokumenteve me afat ruajtjeje të përkohshme, që
          propozohen për asgjësim nga institucionet fondkrijuese ose vetë
          DPA-ja. Përgjegjësi zbaton edhe detyra të tjera të caktuara nga eprori
          direkt dhe drejtori i Përgjithshëm.
        </p>
        <p>
          Inspektorët e këtij sektori marrin pjesë aktive në drejtimin dhe
          mbikëqyrjen e projekteve të përpunimit të dokumentacionit arkivor në
          Arkivin Qendror Shtetëror. Ata hartojnë projekt-plane pune, ndjekin
          zbatimin e tyre, udhëzojnë dhe japin këshilla për tejkalimin e çdo
          vështirësie që mund të lindë gjatë përpunimit të dokumenteve, deri në
          dorëzimin final në vendruajtje bashkë me inventarët përkatës.
          Inspektorët gjithashtu kanë detyrën të verifikojnë inventarët dhe të
          shqyrtojnë dokumentacionin me afat ruajtjeje të përkohshme, të
          propozuar për asgjësim nga institucionet apo nga DPA-ja. Për këto
          fonde, ata përgatisin relacionet përkatëse dhe i paraqesin për
          shqyrtim Komisionit Qendror të Ekspertizës, të cilin e shërbejnë edhe
          si sekretarë teknikë. Në përmbushje të përgjegjësive të tyre, ata
          zbatojnë çdo detyrë tjetër të përcaktuar nga eprori direkt dhe
          drejtori i Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-promovimit",
    title: "Drejtoria e Promovimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Promovimit është një njësi e rëndësishme e Drejtorisë së
          Përgjithshme të Arkivave, e cila mbulon veprimtari të ndryshme që
          lidhen me komunikimin, edukimin, bashkëpunimin ndërinstitucional dhe
          përfaqësimin ndërkombëtar.
        </p>
        <p>
          Kjo drejtori përbëhet nga 4 (katër) sektorë:
          <ol>
            <li>Sektori i Mediave dhe Audiovizualit,</li>
            <li>Sektori i Eventeve dhe Ekspozitave,</li>
            <li>Sektori i Programeve Edukative dhe Shkencore,</li>
            <li>Sektori i Projekteve dhe Marrëdhënieve me Jashtë.</li>
          </ol>
        </p>
        <p>
          Drejtoria e Promovimit ka një rol koordinues ndërmjet sektorëve të saj
          dhe bashkërendon veprimtarinë me strukturat e tjera të Drejtorisë së
          Përgjithshme të Arkivave, me qëllim realizimin efikas të objektivave
          institucionale në fushën e promovimit dhe përhapjes së vlerave
          arkivore.
        </p>
        <p>
          Gjithashtu, Drejtoria merr pjesë aktive në hartimin e
          projekt-rregulloreve, manualeve dhe dokumenteve të tjera udhëzuese për
          të gjitha nivelet e Drejtorisë së Përgjithshme të Arkivave, duke u
          bazuar në konsultime të gjera dhe bashkëpunim me strukturat përkatëse.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-promovimit/drejtori-i-promovimit",
    title: "Drejtori i Promovimit",
    image: null,
    name: "",
    content: <></>,
  },
  {
    path: "/struktura/drejtoria-e-promovimit/sektori-i-mediave-dhe-audiovizual",
    title: "Sektori i Mediave dhe Audiovizual",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Mediave dhe Audiovizualit është pjesë përbërëse e Drejtorisë
          së Promovimit dhe përbëhet nga përgjegjësi i sektorit, specialistë dhe
          arkivistë. Ky sektor ka një rol të rëndësishëm në përfaqësimin e
          Drejtorisë së Përgjithshme të Arkivave në media dhe në ruajtjen,
          përpunimin dhe promovimin e materialeve audiovizuale që lidhen me
          trashëgiminë arkivore.
        </p>
        <p>
          Përgjegjësi i sektorit planifikon dhe siguron realizimin në kohë të
          veprimtarive të sektorit, duke monitoruar vazhdimisht zbatimin e tyre
          sipas standardeve dhe procedurave të përcaktuara. Ai harton projekte
          të përbashkëta me sektorë dhe drejtoritë e tjera, kontrollon ecurinë e
          rubrikave audiovizuale dhe udhëzon specialistët në përmbushjen e
          detyrave të tyre. Gjithashtu, përgatit prezencën ditore e mujore të
          punonjësve dhe raporton për disiplinën e punës, duke garantuar
          zbatimin e rregullores së brendshme.
        </p>
        <p>
          Specialisti për Marrëdhënie me Median dhe Organizimin e Ngjarjeve
          Mediatike krijon dhe mban kontakte të ngushta me përfaqësues të
          mediave, planifikon dhe menaxhon konferenca për shtyp, koordinon
          pjesëmarrjen në ngjarje publike dhe siguron që ngjarjet të mbulohen në
          mënyrë të duhur. Ai gjithashtu harton komunikata për media dhe
          bashkëpunon ngushtë me gazetarët për mbulimin e aktiviteteve të
          DPA-së.
        </p>
        <p>
          Specialisti për Monitorimin e Përdorimit të Arkivave në Media analizon
          mënyrën e përdorimit të materialeve arkivore në media të ndryshme,
          vlerëson efektivitetin e aktiviteteve dhe përgatit raporte të
          detajuara. Ai siguron respektimin e politikave të institucionit dhe
          etikës profesionale, duke mbështetur gjithashtu gazetarët në
          përdorimin e materialeve arkivore për projekte të ndryshme.
        </p>
        <p>
          Arkivisti me profil Dizajneri kryen punën e përgatitjes grafike të
          materialeve promovuese, realizon video dhe editon materiale
          multimediale për rrjetet sociale dhe platformat e tjera të komunikimit
          të DPA-së. Ai kontribuon ndjeshëm në identitetin vizual të
          institucionit dhe në përhapjen sa më të gjerë të aktiviteteve dhe
          pasurisë arkivore.
        </p>
        <p>
          Arkivisti tjetër i sektorit mbështet punën e përditshme të sektorit
          përmes promovimit të vazhdueshëm të pasurisë arkivore në rrjetet
          sociale, ndihmon në realizimin e rubrikave audiovizuale dhe merret me
          dokumentimin vizual të eventeve të DPA-së, duke siguruar një pasqyrë
          cilësore dhe të qëndrueshme të aktivitetit institucional.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-promovimit/sektori-i-eventeve-dhe-ekspozitave",
    title: "Sektori i Eventeve dhe Ekspozitave",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Eventeve dhe Ekspozitave, pjesë e Drejtorisë së Promovimit,
          ka në përbërje përgjegjësin e sektorit, specialistë dhe administratorë
          dokumentesh. Ky sektor ka për qëllim organizimin, koordinimin dhe
          zhvillimin e aktiviteteve publike, edukative dhe kulturore që lidhen
          me promovimin dhe prezantimin e trashëgimisë arkivore përmes
          ekspozitave, konferencave dhe ngjarjeve të tjera të veçanta.
        </p>
        <p>
          Përgjegjësi i sektorit mbikëqyr të gjitha veprimtaritë që lidhen me
          organizimin e eventeve, zhvillon konceptet e tyre, bashkëpunon me
          institucione të ndryshme kulturore dhe arsimore, si dhe menaxhon
          buxhetin dhe burimet përkatëse. Ai kujdeset për përzgjedhjen dhe
          përgatitjen e hapësirave, pajisjeve, mbledhjen e feedback-ut, dhe
          përgatitjen e raporteve të analizës pas-ngjarjeve, duke bashkëpunuar
          ngushtësisht edhe me sektorin e mediave për mbulim të plotë të
          ngjarjeve.
        </p>
        <p>
          Specialistët e sektorit mbulojnë një sërë detyrash të ndërlidhura.
          Njëri ndër ta ka në fokus organizimin e aktiviteteve në muze dhe
          amfiteatër, duke u kujdesur për planifikimin e ngjarjeve kulturore e
          edukative dhe koordinimin me pjesëmarrës të ndryshëm. Një tjetër
          specialist është i angazhuar me kurimin e ekspozitave në Muzeun
          Arkivor, duke zhvilluar koncepte, dizajne dhe përmbajtje tërheqëse në
          bashkëpunim me artistë dhe profesionistë të fushës.
        </p>
        <p>
          Një rol tjetër kyç është ai i specialistit që mbikëqyr dhe menaxhon
          mbarëvajtjen e aktiviteteve dhe ekspozitave, duke siguruar që gjithçka
          të realizohet sipas planifikimit, logjistikës dhe kalendarit të
          përcaktuar. Ai përgatit gjithashtu raporte mbi pjesëmarrjen dhe
          ndikimin e ngjarjeve, si dhe mbështet promovimin përmes kanaleve të
          komunikimit publik.
        </p>
        <p>
          Në ambientet e auditorit, një tjetër specialist kujdeset për
          menaxhimin teknik dhe funksional të sallës dhe pajisjeve të regjisë,
          duke garantuar një zhvillim pa pengesa të aktiviteteve dhe koordinim
          të vazhdueshëm me pjesëmarrësit.
        </p>
        <p>
          Administratori i muzeut merret me shitjen e biletave dhe suvenireve,
          menaxhon aktivitetet e muzeut, ndihmon vizitorët dhe mbikëqyr sigurinë
          gjatë ngjarjeve. Ai gjithashtu përfshihet në shpjegimin e ekspozitave
          dhe në mbështetjen e grupeve edukative. Ndërkohë, administratori i
          sistemit digjital të muzeut ka në ngarkim përditësimin dhe
          mirëmbajtjen e informacionit në platformën online, ruajtjen e
          materialeve arkivore në format digjital dhe monitorimin e vizitave e
          aktiviteteve të muzeut përmes sistemit.
        </p>
        <p>
          Sektori i Eventeve dhe Ekspozitave përbën një nyje kyçe në promovimin
          e pasurisë arkivore për publikun, duke përçuar përmes eventeve dhe
          ekspozitave rëndësinë dhe vlerat e trashëgimisë dokumentare shqiptare.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-promovimit/sektori-i-programeve-edukuese-e-shkencore",
    title: "Sektori i Programeve Edukuese e Shkencore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Programeve Edukuese dhe Shkencore është një njësi thelbësore
          në strukturën e Drejtorisë së Promovimit, me një mision të qartë për
          të zhvilluar dhe zbatuar programe me karakter edukativ dhe shkencor që
          lidhen ngushtësisht me dokumentacionin arkivor dhe rëndësinë e tij
          historike e kulturore. Ky sektor përbëhet nga përgjegjësi i sektorit,
          specialistë dhe administratorë dokumentesh.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues në planifikimin dhe mbikëqyrjen
          e aktiviteteve mujore dhe vjetore, duke propozuar temat për botime,
          broshura dhe materiale informuese që pasqyrojnë vlerat e pasurisë
          dokumentare të Drejtorisë së Përgjithshme të Arkivave. Ai harton
          projekte, bashkëpunon me institucione dhe organizata ndërkombëtare për
          organizimin e veprimtarive shkencore, si dhe siguron përgatitjen
          teknike për konferenca dhe simpoziume. Në të njëjtën kohë, përgjegjësi
          mban kontakte të vazhdueshme me sektorët e tjerë dhe ndan detyrat ndër
          specialistët në përputhje me legjislacionin në fuqi.
        </p>
        <p>
          Specialistët e sektorit mbulojnë një gamë të gjerë aktivitetesh. Njëri
          prej tyre, me profil historian apo kërkues shkencor, organizon
          konferenca dhe seminare shkencore, përzgjedh temat dhe abstraktet për
          botime dhe koordinon realizimin e revistës arkivore. Ai gjithashtu
          përfshihet në zhvillimin e programeve edukative për studentë e nxënës
          dhe bashkëpunon ngushtësisht me sektorët e tjerë në realizimin e
          projekteve botuese dhe hulumtuese.
        </p>
        <p>
          Një tjetër specialist është përgjegjës për ekspozitat shkencore, duke
          realizuar hulumtime të thelluara për krijimin e ekspozitave që
          pasqyrojnë rëndësinë e arkivave në kontekstin historik e kulturor. Ai
          harton materialet për ekspozita, bashkëpunon me sektorin e muzeut dhe
          komunikimit për promovimin e tyre dhe siguron që dokumentacioni
          përkatës të jetë i përditësuar dhe i aksesueshëm.
        </p>
        <p>
          Gjithashtu, sektori përfshin një specialist të kërkimit shkencor dhe
          edukativ, i cili përgatit materiale mësimore dhe zhvillon aktivitete
          për të rinjtë përmes platformave online. Ai organizon seminare dhe
          bashkëpunon me institucione akademike në funksion të kërkimeve të
          përbashkëta dhe edukimit të brezave të rinj mbi rëndësinë e
          trashëgimisë dokumentare.
        </p>
        <p>
          Në mbështetje të punës shkencore dhe botimeve, një administrator me
          funksion redaktori përgatit materialet për botim, koordinon procesin e
          redaktimit dhe bashkëpunon ngushtësisht me specialistët për të
          garantuar cilësinë dhe saktësinë e përmbajtjes së materialeve të
          publikuara nga Drejtoria e Promovimit.
        </p>
        <p>
          Së fundi, një administrator tjetër mbështet organizimin praktik të
          veprimtarive edukative dhe shkencore, duke përgatitur dokumentacionin
          përkatës, menaxhuar regjistrat e pjesëmarrësve dhe siguruar një
          zhvillim të qetë dhe efikas të çdo aktiviteti.
        </p>
        <p>
          Sektori i Programeve Edukuese dhe Shkencore ka një rol strategjik në
          nxitjen e ndërgjegjësimit publik, promovimin e dijeve historike dhe
          thellimin e bashkëpunimeve ndërinstitucionale përmes veprimtarive
          edukative dhe kërkimeve shkencore të lidhura ngushtë me pasurinë
          arkivore të vendit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-promovimit/sektori-i-projekteve-dhe-marredhenieve-me-jashte",
    title: "Sektori i Projekteve dhe Marrëdhënieve me Jashtë",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Projekteve dhe Marrëdhënieve me Jashtë luan një rol kyç në
          zhvillimin e projekteve kombëtare dhe ndërkombëtare të lidhura me
          arkivat, si dhe në menaxhimin dhe përmirësimin e marrëdhënieve me
          partnerë të jashtëm. Ky sektor është përgjegjës për koordinimin dhe
          realizimin e aktiviteteve të ndryshme që promovojnë arkivat dhe
          pasuritë dokumentare të Shqipërisë në arenën ndërkombëtare.
        </p>
        <p>
          Përgjegjësi i sektorit ka një rol të rëndësishëm në hartimin dhe
          menaxhimin e projekteve, duke siguruar që ato të realizohen brenda
          afateve dhe të përmbushin objektivat e parashikuara. Ai është
          përgjegjës për negociatat dhe menaxhimin e marrëveshjeve, kontratave
          dhe protokolleve me partnerët ndërkombëtarë, duke siguruar
          përputhshmërinë e tyre me legjislacionin dhe politikat institucionale.
          Përgjegjësi gjithashtu planifikon dhe koordinon aktivitete
          ndërkombëtare, duke përfshirë seminare, konferenca dhe takime me nivel
          të lartë që promovojnë arkivat dhe rëndësinë e tyre.
        </p>
        <p>
          Specialistët në këtë sektor mbulojnë një gamë të gjerë aktivitetesh të
          orientuara nga zhvillimi i projekteve dhe marrëdhënieve me partnerët
          ndërkombëtarë. Specialistët e zhvillimit të projekteve janë përgjegjës
          për planifikimin dhe menaxhimin e projekteve ndërkombëtare, si dhe për
          mbajtjen e kontakteve dhe koordinimin e bashkëpunimeve me institucione
          arkivore dhe organizata ndërkombëtare. Ata gjithashtu përgatisin
          raportet dhe dokumentacionin e nevojshëm për zhvillimin e projekteve
          dhe analizojnë ndikimin e tyre.
        </p>
        <p>
          Një specialist tjetër merret me menaxhimin e marrëveshjeve dhe
          aktiviteteve të jashtme, duke mbikëqyrur ekzekutimin e marrëveshjeve
          ndërkombëtare dhe koordinuar seminaret dhe aktivitete të ndryshme
          ndërkombëtare. Ai gjithashtu është përgjegjës për organizimin dhe
          mbështetje të vizitave dhe takimeve ndërkombëtare, duke siguruar një
          organizim efikas dhe profesional të detajeve logjistike.
        </p>
        <p>
          Në këtë sektor, është gjithashtu thelbësore krijimi dhe implementimi i
          strategjive afatgjata për bashkëpunim të qëndrueshëm me organizata dhe
          institucione të jashtme, duke mbajtur marrëdhënie të forta dhe të
          dobishme për zhvillimin e projekteve dhe aktiviteteve ndërkombëtare.
          Raportimi i rregullt mbi statusin e projekteve dhe përparimi i
          aktiviteteve është një detyrë e rëndësishme, duke siguruar që çdo
          aktivitet të jetë i përmbushur me sukses dhe sipas planifikimeve.
        </p>
        <p>
          Sektori i Projekteve dhe Marrëdhënieve me Jashtë ka një ndikim të madh
          në rritjen e shikueshmërisë dhe njohjes ndërkombëtare të
          institucioneve të arkivave dhe promovimin e arkivave të Shqipërisë në
          nivel global.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore",
    title: "Drejtoria e Arkivave Shtetërore Vendore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Arkivave Shtetërore Vendore është një nënstrukturë e
          Drejtorisë së Përgjithshme të Arkivave dhe ka përgjegjësinë për
          ruajtjen, përpunimin dhe administrimin e dokumentacionit arkivor të
          Arkivave Shtetërore Vendore (ASHV-ve). Kjo drejtori operon në
          përputhje me ligjin “Për Arkivat”, duke siguruar ruajtjen dhe
          menaxhimin e dokumenteve arkivore në nivel vendor.
        </p>
        <p>
          Drejtoria e Arkivave Shtetërore Vendore është e organizuar në 4
          (katër) sektorë që mbulojnë rajone të ndryshme të Shqipërisë:
          <ol>
            <li>Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës,</li>
            <li>
              Sektori i Arkivave Shtetërore Vendore Elbasan, Korçë dhe Berat,
            </li>
            <li>
              Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër,
            </li>
            <li>
              Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe
              Kukës.
            </li>
          </ol>
        </p>
        <p>
          Përgjegjësia kryesore e Drejtorisë së Arkivave Shtetërore Vendore
          është të koordinojë veprimtarinë e kësaj strukture me pjesët e tjera
          të Drejtorisë së Përgjithshme të Arkivave, në përputhje me fushat e
          saj të kompetencës. Kjo drejtori siguron që çdo veprim arkivor të
          kryhet sipas rregulloreve të përcaktuara, si dhe merr pjesë në
          përgatitjen e rregulloreve dhe manualeve për menaxhimin e arkivave në
          nivelin institucional.
        </p>
        <p>
          Gjithashtu, Drejtoria e Arkivave Shtetërore Vendore ka një rol të
          rëndësishëm në koordinimin me rrjetin arkivor vendor, duke zhvilluar
          takime dhe analiza të punës arkivore për të siguruar menaxhim efikas
          dhe të përputhshëm me kërkesat ligjore dhe institucionale. Kjo
          strukturë gjithashtu merr pjesë në zhvillimin e projekteve dhe
          rregulloreve të reja për të siguruar një administrim të suksesshëm të
          dokumenteve arkivore dhe ruajtjen e materialeve të rëndësishme për
          historinë dhe kulturën kombëtare.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore/drejtori-i-arkivave-shteterore-vendore",
    title: "Drejtori i Arkivave Shtetërore Vendore",
    image: LorencAgalliu,
    name: "Lorenc Agalliu",
    content: (
      <>
        <p>
          Ka përfunduar studimet e larta në degën Histori pranë Fakultetit të
          Historisë dhe Filologjisë në Universitetin e Tiranës (1997-2001). Në
          vitin 2010 ka kryer Master Shkencor në Histori. Në vitin 2016, ka
          mbrojtur titullin Doktor i Shkencave në fushën e Historisë, në
          Universitetin e Tiranës.
        </p>
        <p>
          Që nga viti 2001 punon pranë Drejtorisë e Përgjithshme të Arkivave.
          Gjatë kësaj periudhe ka punuar në sektorë të ndryshëm; 2001-2006
          specialist/arkivist; 2006-2009 Drejtor i Arkivit Shtetëror Vendor
          Durrës; 2009-2019 specialist/arkivist; 2019-2021 Përgjegjës Sektori në
          Sektorin e Përpunimit të Dokumentacionit të ASHV-ve.
        </p>
        <p>
          Ka kryer disa trajnime në fushën e arkivistikës, të organizuara nga
          DPA Tiranë dhe arkivat e vendeve të ndryshme të Evropës.
        </p>
        <p>
          Ka kryer Stazhin Teknik Ndërkombëtar të Arkivave në Arkivin Nacional
          të Parisit, Francë (prill-qershor 2004).
        </p>
        <p>
          Ka kryer Stazhin Teknik Ndërkombëtar të Arkivave në Arkivin Nacional
          të Parisit, Francë (prill-qershor 2004).
        </p>
        <p>
          Ka marrë pjesë në projekte të ndryshme që lidhen me fushën arkivore.
          Pjesëmarrës në zbatimin e Projektit STAR I, Document Management
          System, Protocol and Archives Inventory dhe trajnues në projektin
          STAR-2, “Për trajnimin e punonjësve të Arkivit dhe Protokollit të
          Njësive të Qeverisjes Vendore”.
        </p>
        <p>
          Referues në disa konferenca shkencore kombëtare dhe ndërkombëtare dhe
          autor i një sërë artikujve shkencorë të publikuara në revista
          shkencore kombëtare dhe ndërkombëtare.
        </p>
        <p>
          Autor i monografisë shkencore me titull “Çështja çame gjatë Luftës së
          Dytë Botërore”. Njohës i mirë i gjuhës italiane, franceze dhe angleze.
          Lindur në Berat në vitin 1976.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore/sektori-i-ashv-tirane-dhe-durres",
    title: "Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës është një nga
          katër sektorët e Drejtorisë së Arkivave Shtetërore Vendore dhe është
          përgjegjës për menaxhimin dhe administrimin e dokumentacionit arkivor
          për rajonin përkatës. Ky sektor ka një strukturë të qartë me detyra të
          ndara ndërmjet përgjegjësit të sektorit, specialistëve dhe
          administratorëve të dokumenteve.
        </p>
        <p>
          Përgjegjësia kryesore e sektorit është të parashikojë dhe planifikojë
          veprimtaritë vjetore, duke siguruar që detyrat të realizohen brenda
          afateve dhe në përputhje me standardet administrative dhe teknike. Ai
          kontrollon dhe monitoron aktivitetet e sektorit, shpërndan punën mes
          specialistëve dhe udhëzon ata në realizimin e detyrave. Gjithashtu, ai
          mbikëqyr zbatimin e rregullores së brendshme dhe mbështet kërkesat e
          punonjësve për mjetet materiale. Përgjegjësia e sektorit përfshin
          gjithashtu kontrollin e saktësisë së përgjigjeve për kërkesat
          social-juridike dhe përgatitjen e materialeve për asgjësim, duke
          siguruar që proceset e punës të jenë në përputhje me rregulloret e
          institucionit.
        </p>
        <p>
          Specialistët e sektorit janë të angazhuar në përpunimin dhe
          administrimin e dokumenteve arkivorë. Ata janë përgjegjës për kryerjen
          e procesit të përpunimit të dokumenteve, pjesëmarrjen në përgatitjen e
          planeve të punës dhe realizimin e kërkesave të punonjësve të DPA-së
          dhe studiuesve për dokumente origjinale. Specialistët gjithashtu
          zëvendësojnë dosjet dhe kutitë e dëmtuara dhe identifikojnë dokumentet
          që kërkojnë restaurim. Ata administrojnë mjediset e vendruajtjes dhe
          mbajnë përgjegjësi për mirëmbajtjen e bazës materiale të nevojshme për
          funksionimin e sektorit.
        </p>
        <p>
          Administratorët e dokumenteve kanë një rol të rëndësishëm në
          verifikimin fizik të dokumenteve dhe mbajtjen e dokumentacionit të
          plotë. Ata evidentojnë dokumentet e dëmtuara dhe sigurojnë që ato të
          dërgohen për restaurim. Po ashtu, ata hartojnë lista veçimi dhe
          regjistrojnë dokumentet që dorëzohen për shfrytëzim. Administratorët
          gjithashtu merren me matjet e temperaturës dhe lagështisë në mjediset
          e ruajtjes së dokumenteve dhe përmbushin kërkesat e studiusëve dhe
          punonjësve për riprodhimin e dokumenteve origjinale.
        </p>
        <p>
          Ky sektor ka një rol të rëndësishëm në menaxhimin e dokumenteve
          arkivorë dhe siguron që të gjithë proceset të zhvillohen në përputhje
          me standardet dhe rregulloret arkivore, duke kontribuar në ruajtjen e
          dokumenteve me vlerë për histori dhe administratën publike.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore/sektori-i-ashv-elbasan-korce-dhe-berat",
    title: "Sektori i Arkivave Shtetërore Vendore Elbasan, Korçë dhe Berat",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Elbasan, Berat dhe Korçë është
          një nga katër sektorët e Drejtorisë së Arkivave Shtetërore Vendore dhe
          është përgjegjës për administrimin dhe menaxhimin e dokumentacionit
          arkivor për rajonin e përmendur. Ky sektor ka një strukturë të
          organizuar me përgjegjësi të qarta dhe detyra të ndara mes
          përgjegjësit të sektorit, specialistëve dhe administratorëve të
          dokumenteve.
        </p>
        <p>
          Përgjegjësia e sektorit është e fokusuar në planifikimin dhe
          realizimin e detyrave vjetore të sektorit, duke siguruar që
          veprimtaritë të kryhen në përputhje me normat administrative dhe
          teknike dhe brenda afateve të përcaktuara. Përgjegjësi i sektorit
          monitoron dhe kontrollon aktivitetet e sektorit, shpërndan punën mes
          specialistëve dhe mbikëqyr zbatimin e rregulloreve të brendshme. Ai
          siguron që të gjitha detyrat të realizohen në kohë dhe bën raporte mbi
          disiplinën e punës. Gjithashtu, përgjegjësi i sektorit merr pjesë në
          pasurimin e fondeve arkivore dhe në koordinimin me inspektorët
          rajonalë për ruajtjen dhe restaurimin e dokumenteve. Ai gjithashtu
          është përgjegjës për sigurimin e saktësisë së përgjigjeve për kërkesat
          social-juridike të qytetarëve dhe përgatitjen e materialeve për
          asgjësim, pas vendimeve të Komisionit Qendror të Ekspertizës.
        </p>
        <p>
          Specialistët e sektorit janë të angazhuar në përpunimin dhe
          administrimin e dokumenteve arkivorë, dhe janë përgjegjës për kryerjen
          e proceseve të përpunimit, përgatitjen e planeve të punës dhe
          raportimin e realizimit të detyrave. Specialistët udhëhoqen të kryejnë
          kërkesat e punonjësve të DPA-së dhe studiuesve për dokumente
          origjinale dhe të realizojnë këto kërkesa brenda afateve të
          përcaktuara. Ata gjithashtu janë të angazhuar në identifikimin e
          dokumenteve të dëmtuara dhe në procesin e restaurimit të tyre.
          Specialistët menaxhojnë mjediset e vendruajtjes dhe mbajnë përgjegjësi
          për mirëmbajtjen e bazës materiale të nevojshme për funksionimin e
          sektorit. Po ashtu, ata bashkëpunojnë me punonjësit e DPA-së për
          realizimin e aktiviteteve kombëtare dhe realizojnë regjistrimin e
          dokumenteve të dorëzuara dhe hartimin e inventarëve të fondeve
          arkivore.
        </p>
        <p>
          Administratorët e dokumenteve janë të angazhuar kryesisht në
          verifikimin fizik të dokumenteve në fondet arkivore dhe plotësimin e
          dokumentacionit shoqërues të procesit. Ata janë përgjegjës për
          evidentimin e dokumenteve të dëmtuara dhe dërgimin e tyre për
          restaurim, hartimin e listave të veçimit dhe riprodhimin e dokumenteve
          origjinale për kërkesat e studiuesve dhe punonjësve të DPA-së.
          Administratorët gjithashtu mbajnë regjistrat e dokumenteve të dhëna
          për shfrytëzim dhe matin temperaturën dhe lagështinë në mjediset e
          ruajtjes së dokumenteve.
        </p>
        <p>
          Ky sektor luan një rol kyç në menaxhimin e dokumenteve arkivorë dhe
          siguron që proceset të jenë të organizuara dhe të realizuara në
          përputhje me standardet arkivore dhe rregulloret përkatëse, duke
          kontribuar në ruajtjen e dokumenteve me vlerë historike dhe
          administrative.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore/sektori-i-ashv-fier-vlore-dhe-gjirokaster",
    title: "Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër
          është përgjegjës për menaxhimin dhe administrimin e dokumenteve
          arkivorë në këtë rajon të Shqipërisë, duke siguruar që dokumentacioni
          të ruhet, përpunohet dhe shfrytëzohet në përputhje me standardet dhe
          rregulloret e miratuara. Ky sektor ka një strukturë të organizuar, që
          përfshin përgjegjësin e sektorit, specialistët dhe administratorët e
          dokumenteve.
        </p>
        <p>
          Përgjegjësi i sektorit ka një rol kyç në sigurimin e zbatimit të
          politikave dhe procedurave për administrimin e dokumenteve arkivorë.
          Detyrat e tij përfshijnë planifikimin dhe realizimin e aktiviteteve të
          sektorit, monitorimin e përmbushjes së detyrave, dhe shpërndarjen e
          punës mes specialistëve të sektorit. Përgjegjësi i sektorit është
          gjithashtu përgjegjës për mbikëqyrjen e zbatimit të rregulloreve dhe
          udhëzimeve të brendshme, si dhe për mbështetje dhe këshillim në rast
          të problematikave të mundshme. Ai gjithashtu ka përgjegjësi për
          pasurimin e fondeve arkivore në bashkëpunim me inspektorët rajonalë
          dhe përgatitjen e materialeve për asgjësim, pas miratimit të
          Komisionit Qendror të Ekspertizës. Përgjegjësi i sektorit është i
          angazhuar gjithashtu për realizimin e kërkesave social-juridike të
          qytetarëve dhe për dorëzimin e faturave pranë DFSHM-së.
        </p>
        <p>
          Specialistët e sektorit janë përgjegjës për realizimin e procesave të
          përpunimit të dokumenteve arkivorë dhe për realizimin e kërkesave të
          studiuesve dhe punonjësve të DPA-së për dokumente origjinale. Ata
          gjithashtu zëvendësojnë dosjet dhe kutitë e dëmtuara sipas
          metodologjisë arkivore dhe administron mjediset e vendruajtjes, duke
          mbajtur në gjendje të mirë bazën materiale dhe kancelarike.
          Specialistët janë gjithashtu të angazhuar në identifikimin e
          dokumenteve të dëmtuara dhe në dërgimin e tyre për restaurim, si dhe
          në përgatitjen dhe regjistrimin e inventarëve të fondeve arkivore të
          sektorit. Ata bashkëpunojnë me punonjësit e DPA-së për realizimin e
          aktiviteteve të ndryshme në shkallë kombëtare dhe kontribuojnë në
          përmirësimin e shfrytëzimit të dokumentacionit në përputhje me
          rregulloret arkivore.
        </p>
        <p>
          Administratorët e dokumenteve janë përgjegjës për verifikimin fizik të
          dokumenteve dhe plotësimin e dokumentacionit shoqërues. Ata
          evidentojnë dokumentet e dëmtuara dhe i propozojnë dërgimin për
          restaurim, hartojnë lista veçimi dhe hedhin tregues emëror për
          dokumentet. Administratorët janë të angazhuar gjithashtu në
          riprodhimin e dokumenteve origjinale për kërkesat e studiuesve dhe
          punonjësve të DPA-së dhe për plotësimin e regjistrave të dokumenteve
          të dhëna për shfrytëzim. Ata gjithashtu kryejnë matjet e temperaturës
          dhe lagështisë në mjediset e ruajtjes së dokumenteve dhe mbikëqyrin
          kushtet e ruajtjes për të siguruar integritetin e materialeve
          arkivore.
        </p>
        <p>
          Ky sektor është i angazhuar për ruajtjen dhe menaxhimin efikas të
          dokumenteve me vlerë historike dhe administrative, duke siguruar që
          ato të jenë të disponueshme për përdorim të ardhshëm dhe të jenë të
          mbrojtura nga dëmtimi i mundshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-shteterore-vendore/sektori-i-ashv-shkoder-lezhe-diber-dhe-kukes",
    title:
      "Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe Kukës",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe Kukës
          është përgjegjës për menaxhimin e dokumenteve arkivorë në këtë rajon,
          duke siguruar ruajtjen dhe përpunimin e dokumentacionit administrativ
          dhe historik në përputhje me normat dhe rregullat e institucionit.
          Sektori përbëhet nga Përgjegjësi i sektorit, Specialistët, dhe
          Administratorët e dokumenteve, dhe ka detyra të rëndësishme për të
          siguruar menaxhimin efikas dhe të sigurt të dokumenteve.
        </p>
        <p>
          Përgjegjësi i sektorit ka detyrën të parashikojë dhe planifikojë
          veprimtaritë në lidhje me detyrat vjetore të përcaktuara për sektorin,
          duke siguruar realizimin e këtyre aktiviteteve në kohë dhe brenda
          normave të përgjithshme. Ai kontrollon dhe monitoron veprimtaritë e
          sektorit sipas plan-evidencave mujore dhe shpërndan punën mes
          specialistëve të sektorit. Gjithashtu, ai mbikëqyr dhe udhëzon
          specialistët, duke ofruar mbështetje dhe këshillim për problematikat
          që mund të hasen gjatë realizimit të detyrave.
        </p>
        <p>
          Përgjegjësi i sektorit gjithashtu mbikëqyr zbatimin e rregullores së
          brendshme të institucionit dhe informon drejtorin e ASHV-ve për
          mbarëvajtjen e punës apo problematikat që mund të hasen. Ai përgatit
          prezencën ditore dhe mujore të punonjësve dhe raporton për disiplinën
          e punës në sektor. Nëpërmjet bashkëpunimit me inspektorët rajonalë, ai
          drejton dhe ndihmon punonjësit për pasurimin e fondeve arkivore dhe
          përgatit materialet për asgjësim në bazë të vendimeve të Komisionit
          Qendror të Ekspertizës. Përgjegjësi i sektorit gjithashtu kontrollon
          saktësinë e përgjigjeve të kërkesave social-juridike të qytetarëve dhe
          dorëzon faturat për pagesë pranë DFSHM-së, brenda afateve të
          përcaktuara.
        </p>
        <p>
          Specialistët janë përgjegjës për kryerjen e proceseve të përpunimit të
          dokumenteve dhe marrin pjesë në përgatitjen e planeve të punës dhe
          raportimit të realizimit të detyrave. Ata kryejnë procese pune që
          kërkojnë bashkëpunim në ekipe dhe sigurojnë realizimin e kërkesave të
          punonjësve të DPA-së dhe studiuesve për dokumente origjinale.
          Specialistët janë gjithashtu përgjegjës për zëvendësimin e dosjeve dhe
          kutive të dëmtuara sipas metodologjisë arkivore dhe për identifikimin
          e dokumenteve të dëmtuara, të cilat duhet të dërgohen për restaurim.
          Ata administrojnë dhe mbajnë përgjegjësi për mjediset e vendruajtjes
          dhe mirëmbajnë bazën materiale dhe kancelarike të nevojshme për
          funksionimin e sektorit. Specialistët bashkëpunojnë me punonjësit e
          DPA-së për realizimin e aktiviteteve të ndryshme në shkallë kombëtare
          dhe sigurojnë përgatitjen e dokumenteve për pranimin dhe regjistrimin
          e tyre sipas rregulloreve metodologjike arkivore.
        </p>
        <p>
          Administratorët e dokumenteve kryejnë verifikimin fizik të dokumenteve
          në fondet arkivore dhe plotësojnë dokumentacionin shoqërues të
          procesit. Ata evidentojnë dokumente të dëmtuara dhe i propozojnë
          dërgimin e tyre për restaurim. Administratorët hartojnë lista veçimi
          dhe hedhin tregues emëror për dokumentet. Ata janë përgjegjës për
          riprodhimin e dokumenteve origjinale sipas kërkesave të studiuesve dhe
          punonjësve të DPA-së që merret me kërkesa social-juridike.
          Administratorët plotësojnë regjistrat dhe mbajnë të dhënat për fondet
          arkivore të hyrë në vendruajtjen e ASHV-së. Ata gjithashtu matin
          temperaturën dhe lagështinë në mjediset e ruajtjes së dokumenteve dhe
          sigurojnë ruajtjen dhe menaxhimin e duhur të tyre, duke siguruar që
          proceset e ruajtjes të jenë në përputhje me rregullat dhe normat
          tekniko-profesionale.
        </p>
        <p>
          Ky sektor ka një rol thelbësor në ruajtjen e trashëgimisë
          administrativë dhe historike të rajonit dhe të vendit në përgjithësi,
          duke siguruar që dokumentet të menaxhohen, ruajnë dhe përpunohen në
          përputhje me legjislacionin dhe rregulloret përkatëse.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore",
    title: "Drejtoria e Shërbimeve Arkivore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Shërbimeve Arkivore (DSHA) është një strukturë e
          rëndësishme brenda Drejtorisë së Përgjithshme të Arkivave, e cila ka
          përgjegjësinë për menaxhimin e marrëdhënieve me studiuesit dhe
          përmbushjen e kërkesave social-juridike. Kjo drejtorinë gjithashtu
          merret me restaurimin e dokumenteve të dëmtuara, trajtimin kimik të
          dokumenteve dhe vlerësimin e parametrave të cilësisë fiziko-kimike të
          letrës dhe kartonit, si dhe me aktivitete të tjera që lidhen me
          ruajtjen dhe përpunimin e dokumenteve.
        </p>
        <p>
          Drejtoria e Shërbimeve Arkivore është e organizuar në katër sektorë,
          secili me detyra dhe përgjegjësi të veçanta. Sektori i Shërbimit ndaj
          Studiuesve është përgjegjës për ofrimin e shërbimeve kërkimore dhe
          asistencë për studiuesit që kërkojnë informacion nga arkivat. Sektori
          i Shërbimit ndaj Qytetarëve merret me përmbushjen e kërkesave të
          qytetarëve për dokumente të caktuara dhe ofron shërbime
          social-juridike. Sektori i Testimeve, Libërlidhjes dhe Mikrofilmimit
          përfshin procese teknike dhe shkencore për të siguruar ruajtjen e
          informacionit në forma të ndryshme, përfshirë mikrofilmimin dhe
          testimin e dokumenteve për cilësinë e tyre. Ndërsa Sektori i
          Restaurimit është përgjegjës për restaurimin e dokumenteve të dëmtuara
          dhe për trajtimin e dokumenteve që kërkojnë ruajtje të veçantë.
        </p>
        <p>
          Drejtoria e Shërbimeve Arkivore koordinon veprimtaritë e këtyre
          sektorëve dhe siguron bashkëpunimin e ngushtë me struktura të tjera të
          Drejtorisë së Përgjithshme të Arkivave. Kjo drejtori menaxhon
          gjithashtu vendruajtjet e saj për bibliotekën, fototekën, fonotekën
          dhe objektet muzeale të Drejtorisë së Përgjithshme të Arkivave, duke
          siguruar mbrojtjen dhe ruajtjen e trashëgimisë kulturore dhe
          historike. Për më tepër, Drejtoria merr pjesë aktivisht në përgatitjen
          e projekt-rregulloreve dhe manualeve për të gjitha nivelet e
          Drejtorisë së Përgjithshme të Arkivave, pas konsultimeve të gjera për
          të siguruar implementimin e praktikave dhe standardeve të duhura.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore/drejtori-i-sherbimeve-arkivore",
    title: "Drejtori i Shërbimeve Arkivore",
    image: ElsaSaka,
    name: "Elsa Saka",
    content: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore/sektori-i-sherbimit-ndaj-studiuesve",
    title: "Sektori i Shërbimit ndaj Studiuesve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Shërbimit ndaj Studiuesve është përgjegjës për ofrimin e
          shërbimeve në kohë për qytetarët, studiuesit, punonjësit e autorizuar
          nga ministritë dhe institucionet e tjera fondkrijuese, si dhe personat
          juridikë privatë. Ky sektor siguron mjetet e kërkimit dhe dokumentet e
          nevojshme për çdo kërkesë, duke u mundësuar aksesin dhe shfrytëzimin e
          dokumenteve sipas kërkesës dhe rregullave për shërbimin me dokumente.
        </p>
        <p>
          Sektori përbëhet nga përgjegjësi i sektorit, specialistët dhe
          administratorët e dokumenteve. Përgjegjësi i sektorit ka detyrën e
          parashikimit dhe planifikimit të veprimtarive vjetore për sektorin,
          duke siguruar që punët të kryhen në përputhje me normat administrative
          dhe afatet e përcaktuara. Ai monitoron dhe kontrollon veprimtaritë e
          sektorit, administron dhe siguron mbarëvajtjen e punës në bibliotekë
          dhe ndjek realizimin e shërbimeve në përputhje me rregulloren e
          bibliotekës dhe shfrytëzimin e dokumenteve arkivore. Përgjegjësi
          gjithashtu mbikëqyr dhe zbaton rregullat për hyrje-dalje në
          institucion dhe siguron ndalimin e shërbimeve për dokumentet që nuk
          janë deklasifikuar.
        </p>
        <p>
          Specialisti i sallës së studimit është përgjegjës për zbatimin e
          rregullores së shfrytëzimit të dokumenteve arkivore dhe për ofrimin e
          shërbimeve ndaj publikut. Ai ndihmon studiuesit e rinj për përdorimin
          e sistemit online dhe shpjegon procedurat e marrjes së shërbimit në
          sallë. Specialistët kryejnë verifikimin fizik të dosjeve dhe
          dokumenteve që kërkohen, si dhe riprodhojnë dokumentet sipas
          kërkesave. Ata gjithashtu kryejnë shërbime në sallën e aparaturave dhe
          me mikrofilma.
        </p>
        <p>
          Specialisti i bibliotekës merret me administrimin e librave dhe
          dokumenteve në bibliotekën e DPA-së. Ai kryen inventarizimin dhe
          klasifikimin e librave, si dhe shërben qytetarëve dhe studiuesve me
          libra dhe periodikë sipas kërkesave të tyre. Specialistët e
          bibliotekës gjithashtu ndihmojnë në përdorimin e sistemit online dhe
          mbështesin studiuesit në kërkesat për libra dhe materiale të tjera.
        </p>
        <p>
          Administratori i dokumentacionit ka një rol të rëndësishëm në
          menaxhimin e hyrje-daljeve të dokumenteve dhe punonjësve në Drejtorinë
          e Përgjithshme të Arkivave. Ai informon qytetarët për rregullat e
          kërkesave social-juridike dhe siguron që kërkesat për fotokopjim dhe
          digjitalizim të dokumenteve të plotësohen në kohë. Administratori
          gjithashtu krijon dhe administron dosje për çdo kërkues dhe kujdeset
          për menaxhimin e postës ditore dhe koordinimin me sektorët përkatës.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore/sektori-i-sherbimit-ndaj-qytetareve",
    title: "Sektori i Shërbimit ndaj Qytetarëve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Shërbimit ndaj Qytetarëve është përgjegjës për ofrimin e
          shërbimeve të kërkesave social-juridike, duke siguruar që qytetarët,
          personat privatë dhe institucionet të mund të aksesojnë
          dokumentacionin e nevojshëm për të dhëna biografike, pronësore, etj.
          Ky sektor ofron shërbime të shpejta dhe të efektshme, duke zbatuar
          ligjin “Për Arkivat” dhe akte të tjera nënligjore.
        </p>
        <p>
          Përgjegjësi i sektorit ka detyrën për të organizuar dhe drejtuar punën
          në sektor, duke siguruar se aktivitetet e sektorit përmbushen sipas
          rregulloreve të përcaktuara. Ai monitoron sistemin elektronik të
          kërkesave social-juridike dhe mbikëqyr zbatimin e rregullores së
          brendshme të institucionit. Përgjegjësi gjithashtu mbikëqyr zgjidhjen
          e kërkesave dhe ankesave të qytetarëve dhe institucioneve dhe siguron
          që kërkesat të ndahen për sektorët e duhur të ASHV-ve dhe arkivave të
          sistemit. Ai është gjithashtu përgjegjës për mbajtjen e
          korrespondencës me sektorët e ASHV-ve dhe arkivave të sistemit, si dhe
          me ofertuesit e jashtëm për problemet që mund të lindin.
        </p>
        <p>
          Specialisti i sektorit ka një rol të rëndësishëm në përpunimin dhe
          menaxhimin e kërkesave social-juridike. Ai është përgjegjës për
          plotësimin e sistemit TEP me të dhëna të reja arkivore dhe për
          skanimin dhe dërgimin e kërkesave tek sektorët përkatës. Specialistët
          nxjerrin indikacione arkivore nga programi kompjuterik TEP dhe
          përgatitin fletë-porositë për çdo indikacion të nxjerrë ose të
          porositur. Ata verifikojnë të dhënat e nxjerra me dosjet fizike dhe
          përgatitin dokumentet për fotokopjim ose digjitalizim. Specialistët
          gjithashtu kthejnë përgjigje për kërkesat me karakter pronësor dhe
          zbatojnë rregulloren për kërkesat social-juridike të qytetarëve.
        </p>
        <p>
          Ky sektor siguron që çdo kërkesë të trajtohet me korrektësi dhe brenda
          afateve të përcaktuara, duke përmbushur nevojat e qytetarëve dhe
          institucioneve për dokumente të nevojshme për çështje ligjore dhe
          administrative.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore/sektori-i-testimeve-liberlidhjes-dhe-mikrofilmimit",
    title: "Sektori i Testimeve, Libërlidhjes dhe Mikrofilmimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Testimeve, Libërlidhjes dhe Mikrofilmimit përfshin një grup
          detyrash dhe përgjegjësish që kanë të bëjnë me ruajtjen, konservimin
          dhe përmirësimin e dokumenteve dhe mikrofilmave përmes proceseve
          teknike dhe kimike. Ky sektor është i strukturuar nga një numër
          specialistësh, përfshirë përgjegjësin e sektorit, specialistët
          (Menaxher Cilësie dhe Menaxher Teknik), dhe laborantin. Çdo individ në
          këtë sektor ka detyra të specifikuara për të realizuar qëllimet e
          përgjithshme të sektorit.
        </p>
        <p>
          Përgjegjësi i sektorit ka për detyrë të sigurojë që aktivitetet e
          sektorit të realizohen në përputhje me standardet dhe afatet e
          përcaktuara. Ai planifikon dhe parashikon veprimtaritë e sektorit,
          duke siguruar që puna të kryhet në kohë dhe sipas procedurave teknike
          dhe administrative. Përgjegjësi i sektorit kontrollon dhe monitoron
          aktivitetet e specialistëve, shpërndan punën e sektorit dhe udhëzon
          specialistët mbi detyrat e ngarkuara. Ai është gjithashtu përgjegjës
          për zbatimin e procedurave të ruajtjes dhe konservimit të dokumenteve
          dhe mikrofilmave, duke kryer trajtime kimike për dokumentet që
          kërkojnë ndërhyrje speciale. Gjithashtu, ai raporton për mbarëvajtjen
          e punës dhe për problemet që mund të hasen gjatë procesit.
        </p>
        <p>
          Specialisti i Laboratorit (Menaxher Cilësie) është përgjegjës për
          menaxhimin e cilësisë dhe përputhshmërinë e procedurave të laboratorit
          me standardet ISO 17025. Ai merr përsipër trajtimin kimik të
          dokumenteve dhe mikrofilmave, duke siguruar që këto dokumente të jenë
          të pastruara dhe të konservuara për një periudhë të gjatë kohore. Ai
          kryen testime të matjes së aciditetit dhe trajtimin antimykotik, duke
          siguruar që dokumentet dhe mikrofilmave të jenë në gjendje të mirë për
          ruajtje afatgjatë. Menaxheri i cilësisë gjithashtu është përgjegjës
          për menaxhimin dhe përditësimin e dokumentacionit të cilësisë dhe për
          raportimin e rezultateve të testimeve, duke siguruar që të gjitha
          procedurat janë në përputhje me kërkesat e standardeve të përcaktuara.
        </p>
        <p>
          Specialisti i Laboratorit (Menaxher Teknik) mbikëqyr procedurat
          teknike dhe siguron që ato të jenë në përputhje me kërkesat e ISO
          17025. Ai është përgjegjës për kontrollin e temperaturës dhe
          lagështisë në laborator për të parandaluar ndikimet negative në
          pajisje dhe mostra, si dhe për pastrimin dhe mirëmbajtjen e
          laboratorit. Specialistët teknikë gjithashtu kryejnë kalibrimin dhe
          mirëmbajtjen e pajisjeve për të siguruar saktësinë e rezultateve. Ata
          sigurojnë që metodat e testimit dhe kalibrimit të jenë të validuara
          dhe të aplikohen saktësisht për të siguruar përsëritshmëri në
          rezultate. Ky specialist gjithashtu kontrollon dhe aprovon rezultatet
          e testimeve dhe ndërmerr veprime korrigjuese për jokonformitetet e
          gjetura gjatë procesit të kontrollit.
        </p>
        <p>
          Laboranti ka për detyrë kryesore të ndihmojë në procesin e
          mikrofilmimit dhe ruajtjes së dokumenteve. Ai terheq materialin për
          mikrofilm dhe fotokopjon kapakët e dosjeve, duke siguruar që të dhënat
          të jenë të mbrojtura dhe të aksesueshme. Laboranti gjithashtu kryen
          skanimin e dokumenteve të mikrofilmuara dhe krijon kopje të sigurisë
          për ruajtjen e të dhënave. Ai është përgjegjës për mirëmbajtjen e
          pajisjeve që përdoren gjatë mikrofilmimit dhe kontrollin e cilësisë së
          filmit. Ky specialist gjithashtu bashkëpunon me sektorin e sallës së
          studimit dhe me sektorin e vendruajtjes për të siguruar që çdo mangësi
          në urdhërat e shërbimit të dokumentohet dhe të korrigjohet. Në rast se
          kërkohet trajtim kimik i mikrofilmave, laboranti siguron që ky proces
          të realizohet siç duhet.
        </p>
        <p>
          Ky sektor është një komponent i rëndësishëm në menaxhimin e
          dokumenteve dhe mikrofilmave, duke siguruar që materialet të ruajten
          në kushte optimale dhe janë të aksesueshme për përdorim të mëvonshëm,
          në përputhje me standardet e kërkuara për cilësinë dhe konservimin
          afatgjatë.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-sherbimeve-arkivore/sektori-i-restaurimit",
    title: "Sektori i Restaurimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Restaurimit ka si qëllim restaurimin dhe konservimin e
          dokumenteve të dëmtuara, duke përdorur procese teknike dhe të
          specializuara për t'i kthyer ato në gjendje të përdorshme dhe për të
          siguruar që ato të ruajnë vlerën e tyre historike dhe informuese. Ky
          sektor përbëhet nga dy kategori kryesore përgjegjësish: përgjegjësi i
          sektorit dhe restauratori i dokumenteve.
        </p>
        <p>
          Përgjegjësi i sektorit të Restaurimit ka për detyrë të planifikojë dhe
          të menaxhojë aktivitetet e sektorit, duke siguruar që punët të kryhen
          në përputhje me afatet dhe standardet e përcaktuara. Ai harton dhe
          implementon planin e punës mujor dhe mbikëqyr ekzekutimin e tij.
          Përgjegjësi i sektorit ndan detyrat mes specialistëve të sektorit,
          mbikëqyr punën e tyre dhe ofron mbështetje për zgjidhjen e
          problematikave të ndryshme që mund të lindin gjatë procesit.
        </p>
        <p>
          Ai është gjithashtu përgjegjës për vlerësimin dhe evidentimin e
          dokumenteve të dëmtuara, duke i klasifikuar ato sipas shkakut dhe
          nivelit të dëmtimit. Përgjegjësi i sektorit analizon dhe vlerëson
          kostot për restaurimin e materialeve të paraqitura dhe mund të japë
          rekomandime për zbatimin e procedurave dhe mjeteve të nevojshme për
          restaurim. Ai gjithashtu siguron që të gjitha aktivitetet të
          realizohen sipas legjislacionit dhe rregullores së brendshme të
          institucionit dhe i raporton drejtorit të Përgjithshëm për progresin
          dhe ndonjë problematikë të mundshme.
        </p>
        <p>
          Restauratori është përgjegjës për restaurimin konkret të dokumenteve
          të dëmtuara. Ai përdor teknika të specializuara për të rikthyer dhe
          konservuar dokumentet, duke përfshirë metoda të tilla si kollaritja,
          shtrirja, arnimi dhe përforcimi i dokumenteve. Restauratori përdor
          metilcelulozë për të vitalizuar dokumentin dhe metodat e lagështimit
          dhe presimit për të siguruar që dokumenti të marrë formën e duhur pa
          dëmtuar materialin. Pas procesit të arnimit, restauratori përdor letër
          japoneze për përforcimin e dokumentit, duke i dhënë atij forcë dhe
          qëndrueshmëri. Kur dokumenti është në një gjendje shumë të dëmtuar,
          restauratori mund të përdorë laminim me plastifikim për të siguruar
          mbrojtje shtesë. Restauratori gjithashtu është përgjegjës për çdo
          detyrë tjetër që mund t’i ngarkohet nga eprori direkt ose drejtori i
          Përgjithshëm.
        </p>
        <p>
          Ky sektor është jetik për ruajtjen e trashëgimisë kulturore dhe
          historike, duke u siguruar që dokumentet me vlerë të mbeten të
          paprekura dhe të ruajtura për përdorim të ardhshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore",
    title: "Drejtoria e Arkivave Qëndrore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Arkivave Qendrore është një njësi e specializuar në kuadër
          të Drejtorisë së Përgjithshme të Arkivave, që ka për mision ruajtjen,
          përpunimin dhe administrimin e dokumentacionit arkivor në përputhje me
          parashikimet e ligjit “Për Arkivat”. Ajo siguron që çdo dokument i
          dorëzuar apo i ruajtur në fondet e saj të trajtohet me profesionalizëm
          dhe të menaxhohet në mënyrë të qëndrueshme për ruajtjen e trashëgimisë
          dokumentare të vendit.
        </p>
        <p>
          Kjo drejtori është e organizuar në 4 (katër) sektorë kryesorë:
          <ol>
            <li>Sektori i Arkivit Qëndror Shtetëror,</li>
            <li>Sektori i ish-Arkivit Qendror të Partisë,</li>
            <li>Sektori i Arkivit të Forcave të Armatosura,</li>
            <li>Sektori i Arkivit të Punëve të Brendshme</li>
          </ol>
          Çdo sektor ka funksionin e vet specifik në ruajtjen dhe përpunimin e
          dokumentacionit të trashëguar nga institucionet përkatëse dhe në
          ofrimin e shërbimeve për studiuesit dhe publikun.
        </p>
        <p>
          Drejtoria e Arkivave Qendrore ka gjithashtu rolin e koordinimit të
          veprimtarisë ndërmjet këtyre sektorëve, si dhe të bashkëpunimit me
          strukturat e tjera të Drejtorisë së Përgjithshme të Arkivave. Ky
          koordinim është thelbësor për të garantuar unifikimin e praktikave të
          punës dhe për të siguruar funksionimin efikas dhe të harmonizuar të
          sistemit arkivor në nivel kombëtar.
        </p>
        <p>
          Vendruajtjet qendrore ku ruhen fondet arkivore përfaqësojnë objekte me
          rëndësi të veçantë për sigurinë dhe trashëgiminë kulturore të vendit.
          Këto godina dhe gjithë territori i tyre janë nën mbrojtjen e forcave
          të policisë, të caktuara nga Ministria e Brendshme. Rregullat për
          mbrojtjen fizike, regjimin e hyrje-daljeve dhe sigurinë e ambienteve
          janë të përcaktuara në rregulloren përkatëse të miratuar nga Drejtori
          i Përgjithshëm i Arkivave, e cila siguron integritetin dhe
          paprekshmërinë e dokumentacionit arkivor.
        </p>
        <p>
          Përveç detyrave të ruajtjes dhe përpunimit, Drejtoria e Arkivave
          Qendrore kontribuon edhe në hartimin e projektrregulloreve dhe
          manualeve të punës, që zbatohen në të gjitha nivelet e Drejtorisë së
          Përgjithshme të Arkivave. Këto dokumente hartohen pas një procesi
          konsultimi të gjerë me specialistët dhe institucionet përkatëse, duke
          siguruar që praktikat arkivore të jenë të standardizuara dhe të
          përditësuara me zhvillimet bashkëkohore të fushës.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore/drejtori-i-arkivave-qendrore",
    title: "Drejtori i Arkivave Qëndrore",
    image: ArmandoBoce,
    name: "Armando Boçe",
    content: (
      <>
        <p>
          Armando Boçe ka përfunduar studimet “Master Profesional” në
          Arkivistikë pranë Universitetit të Tiranës, Fakulteti Histori
          Filologji. Gjatë viteve 2003-2004 ka ndjekur studimet pranë Fakultetit
          Histori Filologji, Dega Histori. Prej vitit 2008 punon pranë
          Drejtorisë së Përgjithshme të Arkivave. Specialist arkivist në
          sektorin e Ish-Arkvit të Komiteti Qendror, specialist arkivist në
          Drejtorinë Rajonale të Arkivit Shtetëror Tiranë. Nga viti 2013-2017
          Përgjegjës i sektorit të Digjitalizim dhe Audiovizual, nga maji 2017 e
          vazhdim përgjegjës i sektorit të Promovimit dhe Komunikimit si dhe i
          ngarkuar me punët e Drejtorit të Drejtorisë së Evidencës Qendrore dhe
          Komunikimit.
        </p>
        <p>
          Pjesëmarrës në Konferenca shkencore me temë historike dhe arkivore
          brenda dhe jashtë vendit. Autor i studimeve botuar në fletoren Arkivi
          Shqiptar të DPA dhe Vjetarit të Agjencisë së Arkivave të Kosovës.
          Armando Boçe ka lindur në Tiranë më 27 nëntor 1980. Është i martuar
          dhe baba i dy fëmijëve, një vajze dhe një djali.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore/sektori-i-arkivit-qendror-shteteror",
    title: "Sektori i Arkivit Qëndror Shtetëror",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivit Qendror Shtetëror është një strukturë themelore e
          Drejtorisë së Arkivave Qendrore, e cila përbëhet nga përgjegjësi i
          sektorit, specialistë dhe arkivistë. Ky sektor ka për detyrë kryesore
          ruajtjen, mbrojtjen dhe administrimin e fondeve arkivore që i përkasin
          institucioneve shtetërore, si dhe të dokumentacionit që i është
          dorëzuar Drejtorisë së Përgjithshme të Arkivave nga dhurues privatë
          apo ente të tjera.
        </p>
        <p>
          Përgjegjësi i sektorit ka një rol kyç në drejtimin dhe menaxhimin e
          veprimtarive të përditshme të sektorit. Ai planifikon dhe organizon
          punën për përmbushjen e detyrave vjetore, duke siguruar që proceset të
          zhvillohen në përputhje me normat dhe standardet e përcaktuara.
          Gjithashtu, ai shpërndan detyrat ndërmjet specialistëve, mbikëqyr
          zbatimin e rregullores së institucionit, ndihmon në përpunimin dhe
          zhvendosjen e fondeve, si dhe raportimin për disiplina, probleme apo
          nevoja që lindin gjatë punës.
        </p>
        <p>
          Specialisti i sektorit ka një rol mbështetës dhe teknik, duke siguruar
          funksionimin e përditshëm të mjediseve të vendruajtjes, duke realizuar
          kërkesat për dokumente origjinale nga punonjës të institucioneve, si
          dhe duke marrë në dorëzim dhe regjistruar dokumentacionin e ri të
          ardhur. Ai bashkëpunon ngushtë me strukturat e tjera të DPA-së për të
          garantuar mirëmenaxhimin e fondeve dhe dokumenteve, duke ndjekur
          praktikat e përcaktuara metodologjike dhe tekniko-profesionale.
        </p>
        <p>
          Arkivisti i sektorit është përgjegjës për menaxhimin fizik të
          dokumenteve në vendruajtje. Ai nxjerr dhe sistemon dosje sipas
          kërkesave të studiuesve, riprodhon dokumente për qëllime
          administrative ose studimore, mban regjistrat për fondet e reja dhe
          ato që shfrytëzohen, si dhe ndihmon në procesin e zhvendosjes apo
          evakuimit të fondeve në vendruajtjet përkatëse. Arkivisti kryen
          gjithashtu monitorimin e kushteve klimatike në ambientet e ruajtjes
          dhe ndihmon në identifikimin e dokumenteve që kërkojnë restaurim.
        </p>
        <p>
          Ky sektor përmbush një funksion të rëndësishëm në ruajtjen e kujtesës
          historike dhe institucionale të shtetit, duke garantuar që
          dokumentacioni të ruhet në mënyrë të sigurt, të jetë i aksesueshëm për
          studiuesit dhe të përdoret në funksion të interesit publik dhe
          kërkimeve shkencore.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore/sektori-i-ish-arkivit-qendror-te-partise",
    title: "Sektori i ish-Arkivit Qëndror të Partisë",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i ish-Arkivit Qendror të Partisë është një strukturë e
          rëndësishme në përbërje të Drejtorisë së Arkivave Qendrore, që ka në
          ruajtje dhe administrim dokumentacionin arkivor të ish-Arkivit Qendror
          të Partisë së Punës së Shqipërisë. Ky sektor përbëhet nga përgjegjësi
          i sektorit, specialistë dhe arkivistë, të cilët punojnë së bashku për
          përpunimin tekniko-shkencor të dokumenteve, klasifikimin, shërbimin
          për studiuesit dhe institucionet, si dhe ruajtjen sipas standardeve të
          përcaktuara.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues dhe organizativ. Ai planifikon
          veprimtaritë vjetore, mbikëqyr punën e specialistëve dhe arkivistëve,
          kontrollon proceset e klasifikimit, përpunimit dhe dorëzimit të
          fondeve në vendruajtje. Gjithashtu, siguron zbatimin e rregulloreve
          dhe standardeve arkivore, propozon skema të reja klasifikimi kur është
          e nevojshme dhe ndihmon në përgatitjen e materialeve për Komisionin e
          Deklasifikimit dhe Zhvlerësimit të Dokumenteve. Përgjegjësi është
          gjithashtu përgjegjës për përmbushjen e kërkesave të studiuesve dhe
          qytetarëve, në bashkëpunim me strukturat përkatëse, si dhe për
          zhvendosjen e fondeve nga apo në vendruajtjen e Shkozës.
        </p>
        <p>
          Specialistët e sektorit janë përgjegjës për përpunimin
          tekniko-shkencor të dokumenteve, ndarjen e tyre në fond, vit dhe
          strukturë, si dhe për hartimin e inventarëve dhe katalogëve përkatës.
          Ata shërbejnë dokumente origjinale dhe të mikrofilmuara për
          studiuesit, kujdesen për restaurimin dhe zëvendësimin e materialeve të
          dëmtuara, si dhe mirëmbajnë mjediset e ruajtjes. Një pjesë e
          rëndësishme e punës së tyre përfshin gjithashtu edhe kontrollin e
          kushteve teknike të depozitimit, si temperatura dhe lagështira, për të
          siguruar ruajtjen afatgjatë të dokumenteve.
        </p>
        <p>
          Arkivistët mbështesin funksionimin praktik të sektorit, duke realizuar
          nxjerrjen dhe sistemimin e dosjeve sipas kërkesave të studiuesve,
          riprodhimin e dokumenteve të nevojshme për punonjësit dhe qytetarët,
          si dhe plotësimin e fletëve të vërtetimit dhe regjistrave për fondet e
          reja dhe ato të shfrytëzuara. Ata janë gjithashtu të angazhuar në
          evakuimin e fondeve dhe në përmirësimin e kushteve fizike të ruajtjes
          së tyre, përfshirë matjet periodike të temperaturës dhe lagështirës.
        </p>
        <p>
          Në tërësi, ky sektor luan një rol thelbësor në ruajtjen dhe
          shfrytëzimin e një pjese të rëndësishme të historisë politike të
          Shqipërisë, duke u siguruar që dokumentacioni i ish-Partisë së Punës
          të jetë i aksesueshëm, i përpunuar dhe i ruajtur në përputhje me
          rregullat më të mira arkivore.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore/sektori-i-arkivit-te-forcave-te-armatosura",
    title: "Sektori i Arkivit të Forcave të Armatosura",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivit të Forcave të Armatosura është pjesë përbërëse e
          Drejtorisë së Përgjithshme të Arkivave dhe ka për detyrë
          administrimin, ruajtjen, përpunimin dhe shërbimin e dokumentacionit
          arkivor të krijuar nga strukturat ushtarake ndër vite. Ky sektor
          përbëhet nga përgjegjësi i sektorit, specialistë dhe arkivistë, të
          cilët bashkëpunojnë për realizimin e detyrave arkivore në përputhje me
          legjislacionin dhe normat tekniko-profesionale në fuqi.
        </p>
        <p>
          Përgjegjësi i sektorit të AFA-së kujdeset dhe përgjigjet për ruajtjen
          dhe mbrojtjen e fondeve arkivore të sektorit. Ai planifikon dhe
          organizon veprimtaritë vjetore të sektorit duke siguruar realizimin e
          tyre në kohë dhe në përputhje me normat dhe afatet e përcaktuara.
          Gjithashtu, monitoron përmbushjen e detyrave përmes evidencave mujore
          dhe shpërndan punët mes specialistëve dhe arkivistëve, sipas
          legjislacionit dhe rregullores së institucionit. Përgjegjësi i
          sektorit udhëzon dhe mbështet punonjësit për përmbushjen e detyrave,
          ndjek respektimin e rregullores së brendshme dhe informon drejtuesit e
          DAQ-së mbi ecurinë e punës dhe problematikat e mundshme. Ai përgatit
          prezencën mujore të punonjësve dhe raporton për disiplinën në punë.
          Ndër të tjera, ai mbështet punonjësit për sigurimin e mjeteve
          materiale, kontrollon saktësinë e përgjigjeve për kërkesat
          social-juridike të qytetarëve dhe propozon dokumente për deklasifikim
          pranë Komisionit përkatës. Përgjegjësi i sektorit zbaton edhe detyra
          të tjera sipas urdhrave të drejtorit të Përgjithshëm të Arkivave.
        </p>
        <p>
          Specialisti i sektorit të AFA-së është i ngarkuar me realizimin e
          kërkesave të brendshme për dokumente origjinale, duke siguruar shërbim
          të shpejtë dhe cilësor për drejtori dhe sektorët e tjerë të
          institucionit. Ai harton përgjigjet për kërkesat social-juridike të
          qytetarëve, në bashkëpunim me sektorin e Shërbimit ndaj Qytetarëve,
          dhe kujdeset për administrimin e mjediseve të ruajtjes dhe të bazës
          materiale, duke siguruar kushte të përshtatshme pune. Specialisti
          bashkëpunon me strukturat e tjera të DPA-së për realizimin e
          veprimtarive të ndryshme institucionale dhe merr në dorëzim dokumentet
          e ardhura nga inspektorët e DPA-së, duke përgatitur fletëvërtetimet
          përkatëse sipas afateve të përcaktuara. Ai gjithashtu përgatit listat
          e dokumenteve të klasifikuara për deklasifikim dhe regjistron hyrjen e
          dokumenteve dhe fondeve në vendruajtjen e sektorit. Specialisti zbaton
          çdo detyrë tjetër të përcaktuar nga përgjegjësi i sektorit ose eprori
          direkt.
        </p>
        <p>
          Arkivisti i sektorit të AFA-së është përgjegjës për shërbimin fizik të
          dosjeve, nxjerrjen e tyre sipas kërkesave të studiuesve dhe sistemimin
          pas kthimit. Ai riprodhon dokumente origjinale për punonjësit e
          Drejtorisë së Shërbimeve Historike dhe Administrative që merren me
          çështje pronësie dhe biografike. Arkivisti plotëson fletët e
          vërtetimit të dokumenteve të kërkuara në sallën e studimit dhe mban
          regjistra për fondet e hyra dhe dosjet e dhëna për shfrytëzim. Ai
          kujdeset për matjet periodike të temperaturës dhe lagështirës në
          mjediset e ruajtjes dhe zëvendëson dosjet apo kutitë e dëmtuara në
          përputhje me metodologjinë arkivore. Po ashtu, identifikon dokumentet
          e dëmtuara dhe i dërgon për restaurim kur është e nevojshme. Arkivisti
          zbaton edhe detyra të tjera të caktuara nga përgjegjësi i sektorit ose
          drejtues të tjerë institucionalë.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivave-qendrore/sektori-i-arkivit-te-puneve-te-brendshme",
    title: "Sektori i Arkivit të Punëve të Brendshme",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivit të Punëve të Brendshme është një strukturë e
          rëndësishme e Drejtorisë së Përgjithshme të Arkivave, që përbëhet nga
          përgjegjësi i sektorit, specialistë dhe arkivistë. Ky sektor është
          përgjegjës për ruajtjen, administrimin dhe shërbimin e fondeve
          arkivore të krijuara nga strukturat e Ministrisë së Punëve të
          Brendshme ndër vite, në përputhje me normat dhe standardet teknike
          arkivore.
        </p>
        <p>
          Përgjegjësi i sektorit të APB-së kujdeset për organizimin dhe
          funksionimin e përgjithshëm të sektorit, duke siguruar realizimin në
          kohë të detyrave vjetore të planifikuara. Ai parashikon dhe planifikon
          veprimtaritë e nevojshme në përputhje me standardet administrative dhe
          procedurat teknike, si dhe kontrollon përmbushjen e tyre përmes
          evidencave mujore. Përgjegjësi i sektorit shpërndan detyrat ndërmjet
          specialistëve dhe arkivistëve, udhëzon dhe mbështet stafin në
          përmbushjen e përgjegjësive të tyre, dhe mbikëqyr respektimin e
          rregullores së brendshme të institucionit. Ai informon drejtorin e
          DAQ-së për ecurinë e punës dhe çdo problematikë që mund të haset gjatë
          procesit të punës. Gjithashtu, mbështet nevojat materiale të
          punonjësve, përgatit raportin e pranisë mujore dhe siguron disiplinën
          në punë brenda sektorit. Një pjesë e rëndësishme e punës së tij
          përfshin kontrollin e saktësisë së përgjigjeve për kërkesat
          social-juridike të qytetarëve dhe bashkërendimin me sektorin përkatës.
          Ai propozon deklasifikimin e dokumenteve të klasifikuara pranë
          Komisionit të Deklasifikimit dhe zbaton çdo detyrë tjetër të
          përcaktuar nga drejtori i Përgjithshëm i Arkivave.
        </p>
        <p>
          Specialisti i sektorit të APB-së ka si funksion kryesor realizimin e
          kërkesave të brendshme për dokumente origjinale nga strukturat e tjera
          të DPA-së. Ai përgatit përgjigjet për kërkesat social-juridike të
          qytetarëve në bashkëpunim me sektorin e Shërbimit ndaj Qytetarëve dhe
          administron mjediset e ruajtjes, duke mbajtur përgjegjësi për
          mirëmbajtjen e bazës materiale dhe kancelarike. Specialistët
          bashkëpunojnë ngushtë me punonjësit e tjerë të DPA-së për realizimin e
          detyrave të përbashkëta në nivel kombëtar. Ata marrin në dorëzim
          dokumentet e dorëzuara nga inspektorët e DPA-së dhe përgatisin
          fletëvërtetimet sipas metodologjisë arkivore, brenda afateve të
          përcaktuara. Gjithashtu, verifikojnë dhe përgatisin listën e
          dokumenteve për deklasifikim dhe kryejnë regjistrimin e dokumenteve
          dhe fondeve që hyjnë në vendruajtje. Specialistët zbaton gjithashtu
          edhe detyra të tjera të përcaktuara nga përgjegjësi i sektorit ose
          eprorët e tyre.
        </p>
        <p>
          Arkivisti i sektorit të APB-së është përgjegjës për shërbimin fizik të
          dokumentacionit arkivor. Ai siguron nxjerrjen e dosjeve nga
          vendruajtja sipas kërkesave të studiuesve dhe sistemimin e tyre pas
          përdorimit. Arkivisti riprodhon dokumente origjinale për punonjësit e
          DSHA që punojnë me çështje pronësie dhe biografike, si dhe plotëson
          fletët e vërtetimit për dosjet e kërkuara në sallën e studimit, bazuar
          në urdhrat e drejtorit të Përgjithshëm. Ai mban të përditësuar
          regjistrat me të dhënat për fondet që hyjnë në vendruajtje dhe për
          dosjet që jepen për shfrytëzim. Një pjesë e rëndësishme e punës së tij
          është monitorimi i kushteve të ruajtjes, duke kryer matjet e
          temperaturës dhe lagështirës, si dhe zëvendësimi i dosjeve dhe kutive
          të dëmtuara në përputhje me metodologjinë arkivore. Në rast të
          dokumenteve të dëmtuara, ai kujdeset për identifikimin dhe dërgimin e
          tyre për restaurim. Arkivisti zbaton edhe çdo detyrë tjetër të
          ngarkuar nga përgjegjësi i sektorit ose drejtues të tjerë të
          institucionit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-punes-me-dokumentet",
    title: "Drejtoria e Punës me Dokumentet",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Punës me Dokumentet (DPD) është një strukturë thelbësore
          brenda Drejtorisë së Përgjithshme të Arkivave, e cila mban përgjegjësi
          për regjistrimin dhe evidencimin e fondit arkivor të sektorëve të
          Drejtorisë së Arkivave Qendrore (DAQ) dhe Drejtorive të Arkivave të
          Shërbimeve Vendore (DASHV-ve). Përveç kësaj, DPD ka në fokus procesin
          e digjitalizimit, inovacionin teknologjik dhe ofrimin e mbështetjes
          teknologjike për të gjithë institucionin arkivor.
        </p>
        <p>
          Kjo drejtori është e organizuar në 4 (katër) sektorë:
          <ol>
            <li>Sektori i Regjistrimit dhe Evidencimit</li>
            <li>Sektori i Digjitalizimit</li>
            <li>Sektori i Gjuhëve të Rralla</li>
            <li>Sektori i Përshkrimit Arkivor</li>
          </ol>
          Çdo sektor mbulon një pjesë të rëndësishme të veprimtarisë së
          drejtimit dhe mirëmbajtjes së dokumentacionit arkivor, duke kontribuar
          në sistemimin, përshkrimin, pasurimin dhe aksesueshmërinë e
          dokumenteve në nivel kombëtar.
        </p>
        <p>
          Drejtoria e Punës me Dokumentet ka gjithashtu rol koordinues, duke
          siguruar bashkërendimin e punës midis strukturave të saj të brendshme
          dhe atyre të tjera brenda Drejtorisë së Përgjithshme të Arkivave. Ky
          bashkëpunim është thelbësor për garantimin e një procesi funksional
          dhe të harmonizuar të punës me dokumentet arkivore.
        </p>
        <p>
          Një tjetër funksion i rëndësishëm i DPD-së është pjesëmarrja aktive në
          përgatitjen e projekt-rregulloreve, manualeve dhe dokumenteve të tjera
          udhëzuese që zbatohen në të gjitha nivelet e strukturës arkivore.
          Hartimi i këtyre akteve kryhet në bashkëpunim me sektorët përkatës,
          përmes konsultimeve të gjera me stafet teknike dhe ekspertët në
          terren, me qëllim garantimin e një praktike të unifikuar dhe të
          përmirësuar të administrimit arkivor.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-punes-me-dokumentet",
    title: "Drejtori i Punës me Dokumentet",
    image: null,
    name: "",
    content: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-punes-me-dokumentet/sektori-i-regjistrimit-dhe-evidencimit",
    title: "Sektori i Regjistrimit dhe Evidencimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Regjistrimit dhe Evidencimit është një njësi strukturore
          brenda Drejtorisë së Punës me Dokumentet, i përbërë nga përgjegjësi i
          sektorit, specialistë dhe arkivistë. Ky sektor ka për mision
          menaxhimin, kontrollin dhe mirëmbajtjen e regjistrimit dhe evidencës
          së fondeve arkivore, si në formë fizike ashtu edhe elektronike.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues dhe organizues, duke
          planifikuar dhe parashikuar veprimtaritë vjetore në përputhje me
          detyrat e përcaktuara. Ai siguron që puna të kryhet në kohë, në
          përputhje me standardet administrative dhe teknike. Gjithashtu,
          shpërndan detyrat mes specialistëve të sektorit dhe kontrollon
          zbatimin e punëve mujore, duke u siguruar për respektimin e
          rregullores së institucionit.
        </p>
        <p>
          Ndër detyrat e tij përfshihet regjistrimi dhe kontrolli i fondeve në
          regjistrat fizikë dhe elektronikë, rakordimi i tyre, kontrolli i
          emërtesave të strukturave dhe ofrimi i ndihmës teknike për sektorët e
          tjerë të DPA-së. Ai mban gjithashtu përgjegjësi për raportimin e
          disiplinës në punë dhe për zbatimin e çdo detyre të deleguar nga
          drejtuesit më të lartë.
        </p>
        <p>
          Specialisti 4-II ka për detyrë kryesore regjistrimin e fondeve bazë
          dhe shtesave në regjistrat përkatës, duke përfshirë verifikimin dhe
          korrigjimin e gabimeve teknike. Ai mbështet sektorët e tjerë në
          zgjidhjen e problemeve që lidhen me evidencën dhe organizon
          procesverbalet sipas fondeve. Ndër të tjera, merret me ndërrimin e
          regjistrave të vjetër, bashkimin e fondeve, kontrollin e emërtesave,
          dhënien për shfrytëzim të inventarëve dhe shërbimin e tyre në sallën e
          studimit, duke kontribuar në përditësimin dhe unifikimin e të dhënave
          arkivore.
        </p>
        <p>
          Specialistët 4-III kryejnë verifikime fizike të njësive të ruajtjes,
          duke plotësuar të dhënat që mungojnë në inventarë dhe duke bërë
          unifikimin e informacionit. Ata përmirësojnë elementët formalë të
          dosjeve, si titujt, datat dhe sasitë e fletëve. Në rast mangësish të
          konstatuara, ato raportohen dhe propozohen për zgjidhje, ndërsa
          dokumentet e dëmtuara dërgohen për restaurim. Specialistët zbatojnë të
          gjitha proceset sipas rregullores për shërbimin e dokumentacionit dhe
          detyrave të deleguara nga eprorët.
        </p>
        <p>
          Arkivistët e sektorit mbështesin procesin e evidencës përmes
          zëvendësimit të regjistrave të vjetër, shpërndarjes së inventarëve për
          shfrytëzim, dhe ndarjes së procesverbaleve sipas fondeve. Ata
          gjithashtu angazhohen në përgatitjen e dokumenteve për restaurim, si
          dhe në vendosjen e etiketave dhe përkujdesjen për inventarët fizikë.
          Detyrat e tyre përfshijnë edhe zbatimin e rregullores së shërbimit dhe
          çdo detyrë tjetër të caktuar nga përgjegjësi i sektorit apo eprori
          direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-punes-me-dokumentet/sektori-i-digjitalizimit",
    title: "Sektori i Digjitalizimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Digjitalizimit është një njësi e specializuar brenda
          Drejtorisë së Punës me Dokumentet dhe përbëhet nga përgjegjësi i
          sektorit dhe administratorët e dokumenteve. Ky sektor ka për mision
          transformimin e dokumentacionit arkivor fizik në format digjital, duke
          garantuar ruajtjen, sigurinë dhe aksesueshmërinë e informacionit në
          përputhje me standardet bashkëkohore të arkivistikës dhe teknologjisë.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues dhe mbikëqyrës mbi të gjitha
          veprimtaritë e digjitalizimit. Ai planifikon dhe parashikon detyrat
          vjetore për sektorin, duke u siguruar që të gjitha proceset të
          përmbushen në kohë dhe sipas standardeve teknike dhe administrative.
          Ndër përgjegjësitë e tij përfshihet shpërndarja e detyrave brenda
          ekipit, mbikëqyrja e cilësisë së punës së kryer nga administratorët e
          dokumenteve, si dhe raportimi periodik i progresit të digjitalizimit.
          Përgjegjësi siguron krijimin dhe ruajtjen e kopjeve JPG të materialeve
          të digjitalizuara dhe dërgon kopjet e sigurisë në vendruajtjen e
          përcaktuar në Gjirokastër. Ai gjithashtu mbështet punonjësit me mjetet
          materiale të nevojshme për kryerjen e detyrave dhe kujdeset për
          zbatimin e rregullores së brendshme, duke raportuar në rast problemesh
          apo vështirësish operacionale. Në përputhje me përgjegjësinë e tij, ai
          kontrollon proceset e evidencës elektronike dhe rekomandon ndreqjet e
          nevojshme.
        </p>
        <p>
          Administratorët e dokumenteve janë përgjegjës për procesin teknik të
          digjitalizimit. Ata realizojnë evidencën elektronike të dokumenteve të
          skanuara, duke ndjekur rregullat e përcaktuara të punës. Detyra e tyre
          përfshin kontrollin e cilësisë së materialeve të skanuara përmes
          krahasimit me dokumentet fizike, plotësimin e të dhënave në katalogun
          elektronik, si titujt, datat, vëllimi i fletëve dhe të dhëna të tjera
          përkatëse. Administratorët mirëmbajnë pajisjet e digjitalizimit që
          kanë në ngarkim, lidhin dosjet digjitale me ato të katalogut dhe
          marrin në dorëzim dokumentacionin për digjitalizim. Ata gjithashtu
          zbatojnë çdo detyrë të deleguar nga përgjegjësi i sektorit apo eprori
          direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-punes-me-dokumentet/sektori-i-gjuheve-te-rralla",
    title: "Sektori i Gjuhëve të Rralla",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Gjuhëve të Rralla është një njësi e specializuar brenda
          Drejtorisë së Punës me Dokumentet, i cili ka për mision klasifikimin,
          përpunimin tekniko-shkencor, ruajtjen dhe evidentimin e dokumenteve
          arkivore në gjuhë të rralla si osmanishtja, greqishtja bizantine dhe
          latinishtja. Ky sektor përbëhet nga përgjegjësi i sektorit dhe
          specialistët-arkivistë, të cilët trajtojnë dokumente të periudhave të
          hershme, duke siguruar përpunimin profesional dhe ofrimin e aksesit të
          saktë për studiuesit dhe qytetarët.
        </p>
        <p>
          Përgjegjësi i sektorit planifikon dhe organizon të gjitha veprimtaritë
          vjetore të sektorit, kontrollon dhe monitoron përmbushjen e detyrave,
          duke ndjekur planifikime mujore, gjashtëmujore dhe vjetore. Ai
          mbikëqyr procesin e klasifikimit dhe përpunimit tekniko-shkencor të
          dokumenteve, që përfshin ndarjen e tyre në fond, vit dhe strukturë,
          krijimin e njësive të ruajtjes, si dhe përshkrimin e saktë të titujve
          sipas standardeve metodologjike arkivore. Po ashtu, ai kontrollon
          përpilimin dhe dorëzimin e inventarëve të fondeve dhe bashkëpunon me
          strukturat eprore për përzgjedhjen e fondeve që do të verifikohen dhe
          ripërpunohen. Përgjegjësi ndan punën ndërmjet specialistëve, jep
          mbështetje teknike dhe metodologjike, mban përgjegjësi për prezencën
          dhe disiplinën e punonjësve, dhe raporton për ecurinë dhe
          problematikat e sektorit. Ai gjithashtu kujdeset për pajisjen e stafit
          me mjetet e nevojshme për realizimin e detyrave.
        </p>
        <p>
          Specialistët e këtij sektori janë përgjegjës për përpunimin
          tekniko-shkencor të dokumenteve, duke realizuar klasifikimin, ndarjen
          sipas fondit dhe vitit, organizimin e brendshëm të dokumenteve,
          përkthimin e materialeve dhe hedhjen e të dhënave në katalogun
          elektronik. Ata trajtojnë gjithashtu kërkesat social-juridike të
          qytetarëve dhe institucioneve, duke përkthyer dhe kthyer përgjigje për
          dokumentacionin në gjuhët e rralla, veçanërisht ato me karakter
          pronësor. Specialistët zbatojnë me përpikmëri rregulloret dhe detyrat
          e deleguara nga përgjegjësi i sektorit apo eprori direkt, duke
          siguruar korrektësi profesionale dhe teknike në çdo aspekt të punës së
          tyre.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-punes-me-dokumentet/sektori-i-pershkrimit-arkivor",
    title: "Sektori i Përshkrimit Arkivor",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Përshkrimit Arkivor është përgjegjës për përpunimin dhe
          përshkrimin e dokumenteve arkivorë në përputhje me standardet dhe
          rregulloret ndërkombëtare, si dhe siguron klasifikimin dhe ruajtjen e
          duhur të fondeve arkivore. Ky sektor përbëhet nga përgjegjësi i
          sektorit dhe specialistët, të cilët kryejnë detyra të ndryshme të
          rëndësishme për organizimin dhe menaxhimin e dokumenteve.
        </p>
        <p>
          Përgjegjësi i sektorit ka detyra menaxheriale dhe organizative të
          rëndësishme për mbikëqyrjen dhe planifikimin e aktiviteteve të
          sektorit. Ai planifikon detyrat vjetore, kontrollon dhe monitoron
          aktivitetet e sektorit, dhe shpërndan punën mes specialistëve.
          Përgjegjësi i sektorit siguron që dokumentet të përpunohen sipas
          normave tekniko-profesionale dhe udhëzon specialistët në lidhje me
          detyrat e tyre. Ai gjithashtu mbikëqyr procesin e përshkrimit arkivor
          dhe elektronik të dokumenteve, duke siguruar që ato të përputhen me
          standardin ndërkombëtar ISAD(G) dhe rregulloret e tjera përkatëse.
          Përgjegjësi merr pjesë në përzgjedhjen e fondeve për përshkrim arkivor
          dhe mban përgjegjësi për prezencën dhe disiplinën e punonjësve. Ai
          përgatit materialet për mbledhjet e Komisionit Qendror të Ekspertizës
          dhe mbështet kërkesat e punonjësve në lidhje me mjetet materiale për
          ushtrimin e detyrave të tyre.
        </p>
        <p>
          Specialistët e sektorit janë përgjegjës për realizimin e klasifikimit,
          përpunimit tekniko-shkencor dhe përshkrimit të dokumenteve. Ata
          kryejnë klasifikimin dhe ndarjen e dokumenteve sipas fondeve, vitit
          dhe strukturës, dhe krijojnë njësitë e ruajtjes për dokumentet.
          Specialistët përpunojnë dhe përshkruajnë dokumentet, duke përdorur
          rregullat dhe standardet tekniko-profesionale dhe metodologjike të
          shërbimit arkivor. Ata kryejnë verifikimin e fondeve ekzistuese dhe i
          nënshtrojnë ato procesit të ripërpunimit. Specialistët janë gjithashtu
          përgjegjës për krijimin e mjeteve të informacionit të fondeve dhe
          përshkrimin arkivor elektronik të dokumenteve, sipas standardeve
          ndërkombëtare si ISAD(G). Ata mund të kryejnë detyra të tjera të
          përcaktuara nga përgjegjësi i sektorit dhe eprori direkt.
        </p>
        <p>
          Ky sektor është i rëndësishëm për ruajtjen dhe menaxhimin e
          dokumenteve arkivorë, duke siguruar që informacioni të jetë i
          aksesueshëm dhe i organizuar në mënyrë të rregullt dhe të sigurt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese",
    title: "Drejtoria e Financës dhe Shërbimeve Mbështetëse",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Financës dhe Shërbimeve Mbështetëse është një ndër hallkat
          më të rëndësishme të strukturës organizative të Drejtorisë së
          Përgjithshme të Arkivave. Kjo drejtori ka për mision menaxhimin efikas
          dhe transparent të burimeve financiare dhe njerëzore të institucionit,
          duke kontribuar drejtpërdrejt në përmbushjen e objektivave strategjike
          dhe operacionale të DPA-së. Ajo siguron që veprimtaria e institucionit
          të zhvillohet në përputhje të plotë me legjislacionin në fuqi,
          rregulloret dhe politikat e brendshme administrative.
        </p>
        <p>
          Kjo drejtori ushtron funksione të rëndësishme që lidhen me
          planifikimin buxhetor, zbatimin financiar, organizimin e procedurave
          të prokurimit publik, administrimin juridik, menaxhimin e investimeve,
          si dhe administrimin dhe zhvillimin e burimeve njerëzore. Po ashtu,
          kjo drejtori është përgjegjëse për garantimin e funksionimit të
          përditshëm të institucionit përmes ofrimit të shërbimeve të brendshme
          logjistike dhe administrative.
        </p>
        <p>
          Në funksion të realizimit të misionit të saj, drejtoria bashkërendon
          dhe harmonizon punën me strukturat e tjera të Drejtorisë së
          Përgjithshme të Arkivave, duke ndërtuar ura bashkëpunimi të brendshme
          që rrisin efikasitetin institucional. Gjithashtu, merr pjesë në
          hartimin e projekt-rregulloreve, manualeve dhe dokumenteve të tjera të
          rëndësishme që rregullojnë veprimtarinë e institucionit, duke u bazuar
          në një proces gjithëpërfshirës konsultimesh.
        </p>
        <p>
          Drejtoria e Financës dhe Shërbimeve Mbështetëse përbëhet nga 4 (katër)
          sektorë funksionalë:
          <ol>
            <li>Sektori i Financës dhe Buxhetit,</li>
            <li>Sektori i Prokurimeve, Juridik dhe Investimeve,</li>
            <li>Sektori i Burimeve Njerëzore dhe Sekretari-Arkivit,</li>
            <li>Sektori i Shërbimeve të Brendshme</li>
          </ol>
          Çdo sektor mbulon një fushë të veçantë të veprimtarisë administrative,
          duke bashkëpunuar ngushtë për të siguruar një funksionim të integruar
          dhe efektiv të Drejtorisë së Përgjithshme të Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/drejtori-i-finances-dhe-sherbimeve-mbeshtetese",
    title: "Drejtori i Financës dhe Shërbimeve Mbështetëse",
    image: ArbenDervishllari,
    name: "Arben Dervishllari",
    content: (
      <>
        <p>
          Ka nisur punë në administratë në ish-Nb Maliq në vitin 1985. Pas
          ndryshimit të sistemit politik ka zhvilluar aktivitetin në sektorin
          publik. Ka një kontribut në pjesëmarrjen dhe ngritjen e lëvizjeve
          politike në pluralizëm. Ka mbuluar pozicione drejtuese si Kryetar i PS
          rrethit Korçë. Ka një eksperiencë drejtuese në intitucionet e
          qeverisjes vendore, si drejtor kabineti e këshilltar bashkie. Ka
          mbajtur vendin e Prefektit të Qarkut. Pas viteve 2000 ka mbajtur
          pozicionin e drejtor Drejtorie në Ministrinë e Mbrojtjes. Ka
          organiziuar dhe ka qenë pjesëmarrës në konferenca me objekt rritjen e
          pjesëmarrjes së qytetarëve në proceset shoqërore, rritjen e cilësisë
          së intitucioneve vendimarrëse në nivel vendor dhe qendror.
        </p>
        <p>
          Nga viti 2014 e vazhdim mban vendin e drejtorit të Drejtorisë së
          Financës dhe Shërbimeve Mbështetëse. Është i martuar dha baba i dy
          fëmijëve.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-punes-me-dokumentet",
    title: "Drejtori i Punës me Dokumentet",
    image: null,
    name: "",
    content: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-finances-dhe-buxhetit",
    title: "Sektori i Financës dhe Buxhetit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Financës dhe Buxhetit është një njësi funksionale brenda
          Drejtorisë së Financës dhe Shërbimeve Mbështetëse, që ka për mision
          menaxhimin e buxhetit, financave dhe pasurive materiale të
          institucionit. Ky sektor përbëhet nga përgjegjësi i sektorit,
          specialisti i financës, llogaritari dhe magazinieri.
        </p>
        <p>
          Përgjegjësi i sektorit luan një rol kyç në planifikimin dhe
          mbikëqyrjen e të gjitha veprimtarive financiare, përfshirë përgatitjen
          e planit vjetor të punës, shpërndarjen e detyrave mes punonjësve dhe
          zbatimin e procedurave teknike e ligjore për realizimin e pagesave dhe
          menaxhimin e buxhetit. Ai siguron përgatitjen dhe dorëzimin në kohë të
          dokumentacionit financiar si pasqyrat financiare, bilanci vjetor dhe
          Programi Buxhetor Afatmesëm. Një ndër funksionet e tij është edhe
          mbikëqyrja e regjistrit të prokurimeve publike dhe monitorimi i
          faturave të shpenzimeve apo të ardhurave. Gjithashtu, përgjegjësi
          ndjek veprimet e llogaritarit për deklarimet online dhe kontrollon
          gjendjen e magazinës për pajtueshmëri me dokumentacionin dhe ligjin në
          fuqi. Ai raporton rregullisht për realizimin e buxhetit dhe
          problematika të mundshme pranë drejtorit të DFSHM-së dhe Drejtorit të
          Përgjithshëm të Arkivave.
        </p>
        <p>
          Specialisti i sektorit mbështet proceset buxhetore dhe financiare duke
          kontrolluar dokumentacionin dhe duke siguruar që çdo veprimtari
          ekonomike të jetë në përputhje me ligjin. Ai merr pjesë në përgatitjen
          e buxhetit vjetor dhe afatmesëm, evidenton dhe raporton shpenzimet në
          evidenca mujore dhe periodike, harton bilancin vjetor dhe përgatit
          listëpagesat për pagat sipas strukturës organike. Specialisti
          përditëson sistemin HRMIS dhe kryen deklarimet online pranë
          institucioneve tatimore, si dhe ndjek procedurat me sigurimet
          shoqërore për çështje që lidhen me të drejtat e punonjësve. Ai mban
          lidhje edhe me bankat për veprime në lekë dhe valutë, dhe plotëson
          dokumentacionin për vërtetime page, pensione e procese të ngjashme.
        </p>
        <p>
          Magazinieri është përgjegjës për administrimin e pasurive materiale të
          institucionit dhe për mbarëvajtjen e punës në magazinë. Ai regjistron
          të gjitha hyrje-daljet e materialeve bazuar në dokumentacionin
          përkatës dhe rakordon të dhënat me sektorin e financës në fund të çdo
          muaji. Ndër detyrat e tij është edhe mirëmbajtja e kushteve të
          ruajtjes së mallrave dhe pajisjeve, dokumentimi i aktivëve të
          trupëzuar në përdorim nga punonjësit, si dhe pjesëmarrja në komisionet
          e pranimit të mallrave. Magazinieri siguron që veprimtaria në magazinë
          të zhvillohet në përputhje me legjislacionin në fuqi dhe raportimin
          periodik ndaj drejtuesve të sektorit për çdo problematikë të mundshme.
        </p>
        <p>
          Sektori i Financës dhe Buxhetit është një hallkë jetike për
          funksionimin e qetë dhe të qëndrueshëm financiar të institucionit,
          duke garantuar transparencë, përgjegjshmëri dhe efikasitet në
          menaxhimin e burimeve publike.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-prokurimeve-juridik-dhe-investimeve",
    title: "Sektori i Prokurimeve, Juridik dhe Investimeve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Prokurimeve, Juridik dhe Investimeve është një njësi
          funksionale e Drejtorisë së Financës dhe Shërbimeve Mbështetëse, që
          mbulon fushat e prokurimeve publike, përfaqësimit ligjor dhe
          administrimit të investimeve të institucionit. Ky sektor përbëhet nga
          përgjegjësi i sektorit, një specialist juridik dhe një specialist për
          prokurimet.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol drejtues dhe koordinues për të gjitha
          veprimtaritë e sektorit, duke përfshirë planifikimin dhe monitorimin e
          proceseve gjyqësore ku institucionit është palë, hartimin dhe
          mbikëqyrjen e planit vjetor të prokurimeve dhe ankandeve, si dhe
          kontrollin e bazës së të dhënave të vendimeve gjyqësore. Ai shpërndan
          detyrat mes specialistëve, jep këshillim ligjor për aktet kontraktuale
          me efekte financiare dhe ndihmon në hartimin apo mbikëqyrjen e
          kontratave dhe marrëveshjeve që lidhen me institucionin. Gjithashtu,
          verifikon ligjshmërinë e projektakteve normative të institucionit,
          ndjek prezencën dhe disiplinën e punonjësve të sektorit dhe raporton
          tek drejtori i DFSHM-së. Zbaton gjithashtu çdo detyrë shtesë të
          caktuar nga drejtori i Përgjithshëm i Arkivave.
        </p>
        <p>
          Specialisti juridik përfaqëson institucionin në gjykata dhe përgatit
          dokumentacionin përkatës si autorizime, paditë, prapësimet dhe aktet e
          tjera procedurale. Ai bashkëpunon në hartimin e kontratave me palë të
          treta, përditëson kuadrin ligjor dhe informon institucionin për
          ndryshimet përkatëse. Në kuadër të legjislacionit për parandalimin e
          konfliktit të interesit dhe deklarimit të pasurisë, ai kryen detyrat
          përkatëse si pjesë e strukturës përgjegjëse. Juristi ndjek gjithashtu
          procedurat për regjistrimin dhe verifikimin ligjor të pronave të
          paluajtshme të institucionit në bashkëpunim me ASHK-të dhe strukturat
          e tjera, duke hartuar dosjet përkatëse për çdo pronë.
        </p>
        <p>
          Specialisti i prokurimeve është përgjegjës për organizimin dhe
          ndjekjen e të gjitha procedurave të prokurimit publik në përputhje me
          legjislacionin në fuqi dhe udhëzimet e Agjencisë së Prokurimit Publik.
          Ai përgatit dhe përditëson regjistrin e prokurimeve, harton
          dokumentacionin e nevojshëm për çdo procedurë (si DST, kriteret e
          kualifikimit, urdhrat e prokurimit etj.) dhe bashkëpunon me juristin
          për hartimin e kontratave. Specialisti përpilon raportet mujore dhe
          katërmujore mbi realizimin e prokurimeve dhe i dorëzon ato tek
          përgjegjësi i sektorit dhe pranë Degës së Thesarit. Gjithashtu,
          përgatit korrespondencën me APP-në dhe subjektet pjesëmarrëse,
          sistemon dhe arkivon dosjet e prokurimeve dhe asiston punonjësit e
          tjerë të institucionit në zbatimin e procedurave përkatëse. Ai merr
          pjesë në komisione apo grupe pune të ngritura nga titullari dhe kryen
          çdo detyrë shtesë të caktuar nga eprori direkt.
        </p>
        <p>
          Ky sektor është thelbësor për sigurinë juridike dhe transparencën e
          procedurave të institucionit, duke garantuar që çdo proces të
          zhvillohet në përputhje me ligjin dhe me efikasitet të lartë
          administrativ.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-burimeve-njerezore-dhe-sekretari-arkivit",
    title: "Sektori i Burimeve Njerëzore dhe Sekretari-Arkivit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Burimeve Njerëzore dhe Sekretari-Arkivit është një njësi e
          rëndësishme administrative në strukturën organizative të Drejtorisë së
          Përgjithshme të Arkivave. Ky sektor përbëhet nga përgjegjësi i
          sektorit dhe disa specialistë, të cilët mbulojnë funksione të lidhura
          me administrimin e personelit, menaxhimin e dokumentacionit zyrtar dhe
          arkivor, si dhe proceset e përditësuara të shërbimit civil.
        </p>
        <p>
          Përgjegjësi i sektorit ka rol udhëheqës në organizimin e veprimtarive
          vjetore dhe në mbikëqyrjen e zbatimit të detyrave brenda sektorit. Ai
          siguron realizimin e punës në përputhje me standardet profesionale dhe
          rregulloren e brendshme të institucionit. Në fushën arkivore,
          përgjegjësi mbikëqyr klasifikimin, përpunimin tekniko-shkencor dhe
          sistemimin e dokumenteve sipas normave të shërbimit arkivor në
          Republikën e Shqipërisë. Ndërkohë, në aspektin e burimeve njerëzore,
          ai menaxhon të dhënat në sistemin HRMIS dhe raporton periodikisht
          pranë KMSHC, DAP dhe Kryeministrisë. Ai monitoron gjithashtu zbatimin
          e rregullores së institucionit, administron prezencën ditore dhe
          mujore të punonjësve dhe raporton mbi disiplinën në punë.
        </p>
        <p>
          Specialisti i burimeve njerëzore është përgjegjës për administrimin e
          dosjeve të personelit, përfshirë përditësimin e të dhënave dhe
          ruajtjen e informacionit në përputhje me ligjin. Ai ndjek ecurinë e
          trajnimeve, procedurat për daljen në pension, përgatit kontratat e
          punës dhe zbaton procesin e vlerësimit të performancës dhe përshkrimin
          e pozicioneve të punës. Specialisti gjithashtu ndjek procedurat e
          rekrutimit, ngritjen në detyrë dhe lëvizjen paralele në shërbimin
          civil. Ndër detyra të tjera përfshihet administrimi i lejeve vjetore,
          përgatitja e prezencave mujore për të gjithë sektorët e DPA-së dhe
          korrektimi gjuhësor i shkresave dalëse.
        </p>
        <p>
          Specialisti i sekretarisë administron qarkullimin e korrespondencës
          zyrtare të institucionit, përfshirë pranimin, regjistrimin,
          shpërndarjen dhe dërgimin e saj. Ai siguron përputhjen e dokumenteve
          me kërkesat e legjislacionit dhe kujdeset për mirëmbajtjen e vulave
          dhe dokumenteve institucionale. Po ashtu, hap dhe sistemon dosjet
          sipas pasqyrës emërtuese, plotëson kërkesat për shfrytëzim të
          dokumenteve nga njësitë përbërëse dhe koordinon dorëzimin e dosjeve të
          mbyllura në arkiv.
        </p>
        <p>
          Specialisti i arkivit kryen përpunimin tekniko-shkencor të dokumenteve
          dhe bën vlerësimin e afatit të ruajtjes së tyre. Ai përgatit listat e
          veçimit për dokumentet që kanë përmbushur afatin e ruajtjes dhe
          organizon dorëzimin e materialeve me ruajtje të përhershme në sektorin
          përkatës. Në bashkëpunim me sekretarinë, ai siguron një proces të
          koordinuar për menaxhimin e dokumentacionit dhe zbaton të gjitha
          detyrat sipas normave tekniko-profesionale dhe rregulloreve në fuqi.
        </p>
        <p>
          Ky sektor siguron funksionimin efikas të administrimit të burimeve
          njerëzore dhe menaxhimit të dokumentacionit zyrtar e arkivor të
          institucionit, duke garantuar integritetin, transparencën dhe
          përputhshmërinë me kuadrin ligjor në fuqi.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-sherbimeve-te-brendshme",
    title: "Sektori i Shërbimeve të Brendshme",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Shërbimeve të Brendshme është struktura përgjegjëse për
          organizimin, mbikëqyrjen dhe garantimin e funksionimit efikas të
          logjistikës, shërbimeve mbështetëse, mirëmbajtjes së pajisjeve dhe
          mjeteve të transportit brenda institucionit. Ky sektor përbëhet nga
          përgjegjësi i sektorit, specialistë të fushës, punonjës mbështetës
          teknik dhe shoferë, të cilët bashkëpunojnë për të siguruar kushte
          optimale pune dhe infrastrukturë funksionale për të gjitha strukturat
          e institucionit.
        </p>
        <p>
          Përgjegjësi i sektorit ka si detyrë kryesore planifikimin dhe
          koordinimin e veprimtarive vjetore të sektorit, duke garantuar
          përmbushjen e detyrave në kohë dhe sipas afateve të përcaktuara. Ai
          mbikëqyr punën e specialistëve në lidhje me administrimin e kontratave
          të mirëmbajtjes dhe zbatimin e tyre nga operatorët ekonomikë, si dhe
          kujdeset për organizimin dhe mbikëqyrjen e punës së teknikëve dhe
          shoferëve. Gjithashtu, siguron ndjekjen dhe administrimin e kontratave
          të shërbimit, si edhe të procedurave të likuidimit të faturave
          përkatëse. Në kuadër të funksioneve mbikëqyrëse, përgjegjësi i
          sektorit ndjek dhe raporton mbi prezencën ditore e mujore të
          punonjësve, disiplinën në punë, si dhe zbatimin e rregullores së
          brendshme të institucionit. Një tjetër aspekt i rëndësishëm i rolit të
          tij është edhe monitorimi i gjendjes teknike të mjeteve të
          transportit, përdorimi i karburantit dhe asistenca teknike për
          pajisjet kompjuterike, me qëllim garantimin e një ambienti pune
          funksional.
        </p>
        <p>
          Specialisti i sektorit ndjek në mënyrë të vazhdueshme mirëmbajtjen dhe
          gatishmërinë teknike të automjeteve, riparimin dhe funksionimin e
          pajisjeve zyrtare, si dhe ndihmon në garantimin e higjienës në
          ambientet e institucionit. Ai bashkëpunon ngushtë me juristin përkatës
          për të realizuar procesin e identifikimit dhe inventarizimit të
          pasurive të paluajtshme të administruara nga DPA, përgatitjen e
          dokumentacionit për regjistrimin në Agjencinë Shtetërore të Kadastrës
          dhe hartimin e dosjeve për çdo pronë. Gjithashtu, ai harton
          preventivat për rikonstruksione apo ndërhyrje në godinat e DPA-së dhe
          merr pjesë në procedurat përkatëse të prokurimit si specialist i
          fushës. Një pjesë tjetër e detyrave të tij përfshin ndjekjen e
          kontratave të furnizimit me energji elektrike, ujë dhe pastrim, duke
          ndjekur deri në fund procedurat e faturimit dhe likuidimit.
        </p>
        <p>
          Punonjësit mbështetës, teknikët dhe shoferët kryejnë detyrat e
          përcaktuara në kontratën individuale të punës, në përputhje me planin
          e punës së institucionit dhe nën mbikëqyrjen e Përgjegjësit të
          Sektorit. Ata janë një komponent i rëndësishëm në garantimin e
          funksionimit të përditshëm të infrastrukturës së institucionit dhe
          ofrojnë mbështetje të drejtpërdrejtë logjistike për të gjitha njësitë
          e tij.
        </p>
        <p>
          Sektori i Shërbimeve të Brendshme mbështet funksionimin e
          institucionit përmes një angazhimi të përditshëm për mirëmbajtjen,
          përmirësimin dhe sigurimin e kushteve teknike dhe ambientale që i
          shërbejnë realizimit me efikasitet të misionit të institucionit.
        </p>
      </>
    ),
  },
];
